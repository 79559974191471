body {background: #000000 !important; padding: 12px !important;font-family: 'Poppins-Regular';margin: 0px;}
#layout-wrapper {background: #FBFBFB;border-radius: 16px; /*overflow: hidden;*/ margin-left:270px;transition: all 0.5s ease; }
.no-sidebar  #layout-wrapper {margin-left:0;}
.open #layout-wrapper {margin-left: 135px;}
a,
img {
  border: medium none;
}
html {font-family: 'Poppins-Regular';}
a {
  color: #03198B;
  text-decoration: none;
}

div {
  margin: 0;
  padding: 0;
}

p {
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
  margin: 0px;
  color: #03198B;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  line-height: 30px;
  color: #03198B;
}

a {
  color: #03198B;
  outline: none;
}

ul,
li {
  list-style: none;
}

a:focus,
:focus {
  outline: none;
}

ul,
ol {
  list-style-type: none;
  padding: 0 !important;
  margin: 0 !important;
}

a {
  text-decoration: none;
}

.sticky-top {background: #fbfbfb; border-radius: 16px 16px 0 0;}
.no-sidebar .sticky-top {background: #fbfbfb;border-radius: 16px 16px 0 0;}

.sidebar {
float: left;
width: 270px;
height: 100vh;
position: fixed;
background: #000;
transition: all .5s ease;
-moz-transition: all .5s ease;
-webkit-transition: all .5s ease;
-o-transition: all .5s ease;
z-index:1025;
top: 0; left:0; padding-right: 30px;
}

.main-bar {
float: left;
width: 100%;
padding-top: 10px;
left:0;
z-index: 1; position: relative;
height: 100vh;
text-align: center;
transition: all .5s ease;
-moz-transition: all .5s ease;
-webkit-transition: all .5s ease;
-o-transition: all .5s ease;
}
.open .sidebar { width: 135px; padding-right: 0;}

.main-bar ul { display: flex;
flex-direction: column; row-gap: 15px;
}

.main-bar ul li {
float: left;
width: 100%;
padding: 3px 25px 3px 10px;
overflow: hidden;
display: flex;
position: relative;
transition: all 0.4s ease-in-out;
}


.main-bar ul li a {
  width: 100%;
  padding: 13px 18px;
  border-radius: 8px;
  background: none;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  text-decoration: none;
  outline-width: 0;
}
.main-bar ul li a svg { float: left;}
.main-bar ul li.active a {
  font-family: 'Poppins-Bold'; text-decoration: none; background: #00A86B; 
}
.main-bar ul li:hover a { background: #00A86B;}
.main-bar ul li img { float: left; width: auto;}
.open .main-bar ul li {  display: flex;
  align-items: center;
  justify-content: center;}
.open .main-bar ul li a { width: auto; border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;}
  .main-bar ul li.active span { font-family: 'Poppins-Bold';}
.main-bar ul li span { margin-left: 10px; color: #FFFFFF; font-family: 'Poppins-Light'; font-size: 14px; line-height: 20px; text-decoration: none; opacity: 1; visibility: visible; transition:all .5s ease; -moz-transition:all .5s ease; -webkit-transition:all .5s ease; -o-transition:all .5s ease; padding-top: 2px;}

.main-bar ul li a .hme-icon{background: url("../images/hme-icon.svg") no-repeat 0 0; width: 20px; height: 20px; background-size: 100%;}
.main-bar ul li a .like-icon{background: url("../images/like-icon.svg") no-repeat 0 0; width: 20px; height: 20px; background-size: 100%;}
.main-bar ul li a .contact-icon{background: url("../images/contact-icon.svg") no-repeat 0 0; width: 20px; height: 20px; background-size: 100%;}

.main-bar ul li.active a .hme-icon{background: url("../images/hme-icon-h.svg") no-repeat 0 0; width: 20px; height: 20px; background-size: 100%;}
.main-bar ul li.active a .like-icon{background: url("../images/like-icon-h.svg") no-repeat 0 0; width: 20px; height: 20px; background-size: 100%;}
.main-bar ul li.active a .contact-icon{background: url("../images/contact-icon-h.svg") no-repeat 0 0; width: 20px; height: 22px; background-size: 100%;}



.open .main-bar ul li a span { display: none;}
.m-icons {
  float: left;
  width: 100%;

}

.det-bar { display: none;
  float: left;
  width: 75%;
  height: 100vh;
  /* background: #1D1D1B; */ transition:all .5s ease; -moz-transition:all .5s ease; -webkit-transition:all .5s ease; -o-transition:all .5s ease;
  padding-top: 15px; position: relative; left: 0; z-index: 1; opacity: 1; visibility: visible;
}

.det-bar .main-title {
  background: #ebf0ff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.det-bar .main-title h1 {
  font-family: 'Poppins-Regular';
  font-size: 22px;
  font-weight: 600;
  padding: 10px 20px;
}


.det-bar .list-group-item {
  border: none;
  font-size: 14px;
  padding: 4px 0 4px 30px;
  color: #6b6565;
  border-bottom: 1px solid #eaeaea;
}

.m-cnt {
  background: #1D1D1B;
}

.m-cnt h2 {
  font-family: 'Poppins-Regular';
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #fff;
  padding: 5px 20px 0 0;
}

.m-cnt ul li a {
  font-family: 'Poppins-Regular';
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #fff;
  text-decoration: none;
  padding: 10px 0 0 0;
  float: left;
  width: 100%;
  /* border-bottom: 1px solid #397ADC; */
}

.m-cnt ul li a:hover {
  /* background: #0859d3; */
}

.header {
  display: flex;
  justify-content: right;
  width: calc(100% - 1px);
  padding: 12px 20px 24px; float: right;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition:all 0.5s ease;
  margin-right: 0px !important;
}

.head-lft {
  flex: 0 1 auto;
}

.open .header {
  width:100%;
  /*transition: all 0 ease;
  -moz-transition: all 0s ease;
  -webkit-transition: all 0s ease;
  -o-transition:all 0s ease;*/
}
body.no-sidebar .head-lft-logo {
  display: block!important;
}
.head-lft-logo {
  display: none;
}
.logo {
  float: left;
  padding: 13px 25px 13px 10px;
  transition: all .5s ease;
  -moz-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  left: 0;
  position: relative;
  width: 100%; display: flex;
}

.logo img {
  float: left;
  width: auto;
}
.logo .mob{display: none;}
.open .logo .mob{display: block;}
.open .logo .desktop{display: none;}
.open .logo .mob img{width: auto;}
.open .logo img{width: auto;}

.logo::after {position: absolute;
  content: "";
  left: 0; right: 0; margin: 0px auto;
  bottom: 0;
  background: rgba(255, 255, 255, 21%);
  width: 100%;
  height: 1px;
  transition: all .5s ease;}
.open .logo::after{width: 78px; left: -15px;}

.open .logo { position: relative; text-align: center;justify-content: center;}
/*.sidebar::after { position: absolute; content: ""; top: 0; left: 0; background: url("../images/sidebar-bg.png") no-repeat top left; width: 100%; height: 100%; background-size: cover; z-index: -1; }*/

.search {
  float: left; padding-top: 8px;
}

.head-rgt { position: relative; flex: 0 1 100%; display: flex;justify-content: right;align-items: center; column-gap: 15px;}

.btn.btn-primary.idea-btn{font-size: 16px;
  line-height: 24px;
  color: #000;
  font-family: 'Poppins-SemiBold';
  padding: 0px 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 105px;
  height: 40px;
  text-decoration: none;
  border: none;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-image-slice: 100%;
  border-image-slice: 1;
  border-width: 1px;
  background: transparent;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 12px; column-gap: 10px; background: #77DD77; border: 1px solid #77DD77;}

  .btn.btn-primary.go-back{font-size: 16px;
    line-height: 24px;
    color: #000;
    font-family: 'Poppins-SemiBold';
    padding: 0px 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    height: 48px;
    text-decoration: none;
    border: none;
      border-top-width: medium;
      border-right-width: medium;
      border-bottom-width: medium;
      border-left-width: medium;
      border-image-slice: 100%;
    border-image-slice: 1;
    border-width: 1px;
    background: transparent;
    transition: all 0.5s ease;
    position: relative;
    border-radius: 12px; column-gap: 10px; background: transparent; border: 1px solid #000;}
  .btn.btn-primary.go-back:hover{background: #000; color: #fff;}

  .btn.btn-primary.go-back.go-btn {padding: 0;border: none;text-decoration: underline;font-size: 18px; font-family: 'Poppins-Regular'; height: inherit;transition: all 0.5s ease;}
  .btn.btn-primary.go-back.go-btn:hover{background: transparent; color: #77DD77;}
  .btn.btn-primary.go-back.go-btn .svg-inline--fa.fa-chevron-left {color: #000 !important;}
  .btn.btn-primary.go-back.go-btn:hover .svg-inline--fa.fa-chevron-left {color: #77DD77 !important;}

  .btn.btn-primary.idea-btn:hover span img { fill: #fff;}

  /*.btn.btn-primary.idea-btn::after,  .btn.btn-primary.go-back::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 2px;
    background: linear-gradient(to left, #FFB900, #F96815,#8031A7);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: add, add;
    mask-composite: add, add;
    mask-composite: exclude;
    -webkit-mask-composite: xor;
    transition: all 0.5s ease;
    
  }*/
.btn.btn-primary.idea-btn span {width: 21px;height: 21px;display: flex;align-items: center;justify-content: center;}
.btn.btn-primary.go-back:hover::after, .btn.btn-primary.idea-btn:hover::after, .btn.btn-primary.share-btn:hover::after {content: none;}

.head-rgt ul {
  display: flex;
  column-gap: 12px;
  justify-content: right;
  align-items: center;
}
.head-rgt ul li .sign-btn{font-size: 16px;
  line-height: 24px;
  color: #000;
  font-family: 'Poppins-SemiBold';
  padding: 0px 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  height: 40px;
  text-decoration: none;
  border: 1px solid #63666A;
  background: transparent;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 12px;}
  /*.head-rgt ul li .sign-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 2px;
    background: linear-gradient(to left, #FFB900, #F96815,#8031A7);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: add, add;
    mask-composite: add, add;
    mask-composite: exclude;
    -webkit-mask-composite: xor;
    transition: all 0.5s ease;
    z-index: -1;
  }*/
  .head-rgt ul li .sign-btn strong, .head-rgt ul li .login-btn strong{font-weight: normal;}
  .head-rgt ul li .sign-btn span, .head-rgt ul li .login-btn span{display: none;}

.head-rgt ul li .login-btn{font-size: 16px;
  line-height: 24px;
  padding: 0px 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 130px;
  font-family: 'Poppins-SemiBold', sans-serif; color: #000;
  border-radius: 12px;
  text-decoration: none;
  border: 1px solid #63666A;
  background: transparent;
  transition: all 0.5s ease;
  position: relative;
}

/*.head-rgt ul li .login-btn::after { content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 2px;
  border: linear-gradient(to left, #FFB900, #F96815,#8031A7);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: add, add;
  mask-composite: add, add;
  mask-composite: exclude;
  -webkit-mask-composite: xor;
  z-index: -1;}*/


  .head-rgt ul li .sign-btn:hover, .head-rgt ul li .login-btn:hover {background: #8EE38E; border: 1px solid #8EE38E; color: #000;}
  .btn.btn-primary.idea-btn:hover, .btn.btn-primary.idea-btn.active {background: #8EE38E; border: 1px solid #8EE38E;}

.search input[type="text"] {
  width: 317px;
  height: 37px;
  border-radius: 5px;
  background: #f3f3f9 url(../images/search.svg) no-repeat left 15px center;
  font-family: 'Poppins-Regular';
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #76788d;
  border: none;
  padding: 10px 0 10px 40px;
}

.head-rgt ul.dropdown-menu {
  display: none;
}

.head-rgt ul.dropdown-menu.show {
  display: block;
}

.dropdown p {
  margin: 0 10px;
}

.dropdown p span {
  font-family: 'Poppins-Regular';
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  color: #0455e7;
}

#main-area {
  padding-left:0;padding-right: 0;
  transition: all .5s ease;
  -moz-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease; min-height: 700px;
  clear: both;
}

.title {
  font-family: 'Poppins-Regular';
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #5d596d;
}

.form-label {
  font-family: 'Poppins-Regular';
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #000;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background: url("../images/date.svg") no-repeat right 15px center;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
  border-radius: 6px;
  background: #0455e7;
}

.btn-secondary {
  border-radius: 6px;
  background: #f1f1f2;
  border-color: #f1f1f2;
  color: #818181;

}

.react-dropdown-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg) !important;
  background-clip: padding-box !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.table-bordered tr td{border:none!important ;font-family: 'Poppins-Regular';
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #5d5a68;
  }
.table-bordered tr th{font-family: 'Poppins-Regular';
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #000;background: #f4f4f6;
  border:none!important ;
  }

  .log-rgt form{width: 400px; margin: 0 auto;}
  .log-rgt .l-logo img {
    /*
    width: 100px;
    */
    width: auto;
}

.menu {float: left;padding: 0 0 0 43px; position: absolute;left: 0;bottom: 150px; width: 42px;}
.menu span { background: url("../images/open-sidebar-bar.svg") no-repeat center center; width: 42px; height: 42px; display: block; border-radius: 50%;}
.open .menu {padding: 0; margin: 0 auto; text-align: center; right: 0; display: flex; justify-content: center;}
.open .menu span {background: url("../images/open-sidebar-bar.svg") no-repeat center center;}
.open .det-bar{position: relative; opacity: 0; visibility: hidden;}
.open #main-area{padding-left:0;}
.open .sidebar.shadow{box-shadow: none!important;}
.footer { text-align: center; padding: 24px 0;/*padding-left: 17%;*/ display: none;}
.footer p{ font-size: 12px; line-height: 18px; color: #46494D; font-family: 'Poppins-Regular'; text-align: center; margin-bottom: 0;}
.footer p a{color: #46494D; font-size: 12px; line-height: 18px; font-family: 'Poppins-Regular'; text-decoration: underline; transition: all .5s ease;}
.footer p a:hover{color: #00a86b;}

/* team member */
.peopl-pad {float: left;width: 100%;}
.peopl-pad ul {display: flex;flex-wrap: wrap;justify-content: center;align-items: center;width: 250px;position: relative;padding: 0;row-gap: 25px;margin: 0 auto!important;}
.peopl-pad ul li {flex: 0 1 100%;border-top: 5px solid #ec9b2b;box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);min-height: 100px;position: relative;}
.peopl-pad ul li .p-ig {float: left;width: 30%;padding: 0 10px;margin-top: 15px;}
.peopl-pad ul li .p-ig img {width: 100%;border-radius: 100px;padding: 5px;border: 1px solid #ddd;}
.peopl-pad ul li .p-ct {float: left;width: 70%;padding: 25px 0 0 0px;}
.peopl-pad ul li .p-ct h2 {font-family: 'Poppins-Regular';font-weight: bold;font-size: 14px;line-height: 16px;text-align: left;color: #000;margin: 0;}
.peopl-pad ul li .p-ct p {font-family: 'Poppins-Regular';font-weight: normal;font-size: 14px;text-align: left;color: #76788d;}
.peopl-pad ul li.add-pop {text-align: center;min-height: inherit;box-shadow: none;border: none;position: absolute;right: -150px;bottom: 30px;}
.peopl-pad ul li.add-pop p {font-family: 'Poppins-Regular';font-weight: normal;font-size: 14px;text-align: left;color: #76788d;}
.peopl-pad .uo-ll {float: left;width: 100%;margin-top: 54px;padding: 0;position: relative;}
.peopl-pad .uo-ll .col-lg-4 {padding: 0;}
.peopl-pad .uo-ll .col-lg-4 ul.pl-2 {padding: 0;}
.peopl-pad .uo-ll .col-lg-4 ul.pl-2 li.grey {border-color: #5d737e;}
.peopl-pad .uo-ll .col-lg-4 ul.pl-2 li.blue {border-color: #19a3e4;position: relative;}
.peopl-pad .uo-ll .col-lg-4 ul.pl-2 li.gree {border-color: #18baa8;}
.peopl-pad .uo-ll .col-lg-4 ul.pl-2 li.blue a.apl {position: absolute;bottom: -13px;right: 0;left: 0;margin: 0 auto;width: 30px;}
.peopl-pad .uo-ll .col-lg-4 ul li::before{position: absolute;content: "";width: 1px;height: 27px;background: #5d737e;top: -31px;right: 0;left: 0;margin: 0 auto;}
.peopl-pad .uo-ll::before{position: absolute;content: "";width: 68%;height: 1px;background: #5d737e;top: -27px;right: 0;left: 0;margin: 0 auto;}
.peopl-pad ul li.org::before{position: absolute;content: "";width: 1px;height: 27px;background: #5d737e;bottom: -27px;right: 0;left: 0;margin: 0 auto;}


/* Dashboard  */
.s-tit, .card-title{font-family: 'Poppins-Regular';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #000; text-transform: uppercase;
  }
  .s-para{ 
    font-family: 'Poppins-Regular';
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    color: #989898; margin: 0;
    }
    .lis li a{font-family: 'Poppins-Regular';
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #9e9e9e;
      }
      .lis li a:hover{color: #0455e7; }
      h2.s-tit.gr-cl {
        color: #1bb59d;
    }
    
    h2.s-tit.org-cl {
        color: #f16145;
    }
    
    h2.s-tit.bl-clr {
         
        color: #0455e7;
    }
    h2.s-tit.gr-cl {
      color: #1bb59d;
  }
  
  h2.s-tit.org-cl {
      color: #f16145;
  }
  
  h2.s-tit.bl-clr {
       
      color: #0455e7;
  }
  
  a.t-ta {
      height: 60px;
      background: #5089ef;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px; text-decoration: none;
      
  }
  a.t-ta p{font-family: 'Poppins-Regular';
    font-weight: normal;
    font-size: 11px;
    line-height: 24px;
    text-align: center;
    color: #f8f7fa; margin: 0;
    padding:  0 29px;}
  
  a.t-ta span {
      font-family: 'Poppins-Regular';
      font-weight: bold;
      font-size: 19px; display: block;
      line-height: 26px;
      text-align: center;
      color: #f8f7fa;
  }

a.t-ta.o-ta {
  background: #eba733;
}
ul.b-ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-left: 20px; align-items: center;
  border-bottom: 1px solid #ddd; padding-bottom: 10px;
}
ul.b-ul li:last-child{border: none;}

ul.b-ul li .ta-lft p {
  font-family: 'Poppins-Regular';
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #000;
}

ul.b-ul li .ta-lft span {
  font-family: 'Poppins-Regular';
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  color: #a6a6a6;
  margin: 0;
  padding: 0;
}


ul.b-ul li .ta-rgt span {
  width: 47px;
  height: 22px;
  border-radius: 11px;
  background: #7c93bd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins-Regular';
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #f8f7fa;
}

ul.b-ul li.i-t .ta-rgt span {
  background: #f5ae45;
}

ul.b-ul li.p-t .ta-rgt span {
  background: #45d5b4;
}
 ul.b-ul li.b-t{background: url(../images/t-1.svg) no-repeat left top 5px;}
 ul.b-ul li.i-t{background: url(../images/t-2.svg) no-repeat left top 5px;}
 ul.b-ul li.p-t{background: url(../images/t-3.svg) no-repeat left top 5px;}
  ul.b-ul li div.ta-lft img {
  float: left;
  margin-right: 10px;
}
 ul.b-ul li div.ta-lft {
  flex: 0 1 80%;
}
.nav-tabs .nav-link{font-family: 'Poppins-Regular';
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #000;
  }
  .nav-tabs .nav-link.active{font-weight: bold; color: #5089ef; }
  label{font-family: 'Poppins-Regular';
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000;
    }


    /* employee detail  */
    .profile-view {
      position: relative;
  }
  
  .profile-view .profile-img-wrap {
      height: 120px;
      width: 120px;
      position: absolute;
  }
  
  .profile-view .profile-img-wrap img {
      border-radius: 50%;
      height: 120px;
      width: 120px;
  }
  
  .profile-view .profile-basic {
      margin-left: 140px;
      padding-right: 50px;
  }
  
  .profile-view .profile-basic .profile-info-left {
      border-right: 2px dashed #ddd;
  }
  
  .profile-view .profile-basic .profile-info-left .user-name {
      color: #000;
  }
  
  .profile-view .profile-basic .profile-info-left .staff-id {
      font-size: 14px;
      font-weight:500;
      margin-top: 5px;
  }
  
  .profile-view .profile-basic .profile-info-left .staff-msg {
      margin-top: 30px;
  }
  
  .profile-view .profile-basic .profile-info-left .staff-msg .btn-custom {
      background: #000;
      background: linear-gradient(to right, #000 0%, pink 100%);
      color: #fff;
  }
  
  .profile-view .profile-action .dropdown-toggle:after {
      display: none;
  }
  
  .edit-icon {
      background-color: #ddd;
      border: 1px solid #ddd;
      color: #000;
      float: right;
      font-size: 12px;
      line-height: 24px;
      min-height: 26px;
      text-align: center;
      width: 26px;
      border-radius: 24px;
  }
  
  .personal-info {
      padding: 0;
      margin: 0;
      list-style: none;
  }
  
  .personal-info li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 5px;
  }
  
  .personal-info li .title {
      color: #000;
      float: left;
      font-weight: 600;
      width: 30%; font-size: 14px;
  }
  
  .personal-info li .text {
      color: #000;
      display: block;
      overflow: hidden;
      width: 70%; font-size: 14px;
      float:left;
  }
  
  .personal-info li .text .avatar-box {
      float: left;
      position: relative;
  }
  
  .personal-info li .text .avatar-box .avatar-xs {
      line-height: normal;
  }
  
  .delete-icon {
      color: red;
      float: right;
      font-size: 18px;
  }
  
  .experience-box {
      position: relative;
  }
  
  .experience-box .experience-list {
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
  }
  
  .experience-box .experience-list::before {
      background: #ddd ;
      bottom: 0;
      content: "";
      width: 2px;
      position: absolute;
      top: 8px;
      left: 8px;
  }
  
  .experience-box .experience-list li {
      position: relative;
  }
  
  .experience-box .experience-list li .experience-user {
      background: #fff;
      height: 10px;
      width: 10px;
      position: absolute;
      top: 4px;
      left: 4px;
  }
  
  .experience-box .experience-list li .experience-user .before-circle {
      background-color:#ddd  ;
      border-radius: 50%;
      height: 10px;
      width: 10px;
  }
  
  .experience-box .experience-list li .experience-content {
      background-color: #fff;
      margin: 0 0 20px 40px;
      position: relative;
  }
  
  .experience-box .experience-list li .experience-content .timeline-content {
      color: #000;
  }
  
  .experience-box .experience-list li .experience-content .timeline-content a.name {
      color: #000;
      font-weight: 700;
  }
  
  .experience-box .experience-list li .experience-content .time {
      color: #000;
      display: block;
      font-size: 12px;
      line-height: 1.35;
  }
  
  .submit-btn {
      font-size: 18px;
      font-weight: 500;
      min-width: 200px;
      padding: 10px 20px;
      border-radius: 50px;
  }
  
  .profile-view .profile-img-wrap.edit-img {
      border-radius: 50%;
      margin: 0 auto 30px;
      position: relative;
  }
  
  .profile-view .profile-img-wrap .fileupload {
      background: rgba(33, 33, 33, 0.5);
      border: none;
      padding: 3px 10px;
      border-radius: 0;
      position: absolute;
  }
  
  .profile-view .profile-img-wrap .fileupload .btn-text {
      color: #fff;
  }
  
  .profile-view .profile-img-wrap .fileupload .upload {
      cursor: pointer;
      filter: alpha(opacity=0);
      font-size: 20px;
      opacity: 0;
      position: absolute;
      top: -3px;
      left: -3px;
  }
  
  h5.section-title{font-size: 14px;}
  th{font-size: 14px; font-family: 'Poppins-Regular';
    font-weight: bold;}
    td{ font-family: 'Poppins-Regular'; font-size: 13px;
    }
    .col-md-7 ul.personal-info li {
      margin-bottom: 15px;
  }



.profile-widget {
  background-color: #fff;
  border: 1px solid #e9e9ea;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  
}

.profile-widget .profile-img {
  cursor: pointer;
  height: 80px;
  margin: 10px auto;
  position: relative;
  width: 80px
}

.profile-widget .profile-img .avatar {
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px
}

.profile-widget .dropdown.profile-action {
  text-align: right;
  position: absolute;
  top: 10px;
  right: 5px
}

.profile-widget .dropdown.profile-action .action-icon {
  color: #7a7c7f;
  font-size: 18px;
  display: inline-block
}

.profile-widget .dropdown.profile-action .dropdown-toggle::after {
  display: none
}

.profile-widget .user-name {
  color: #373b3e; font-family: 'Poppins-Regular'; font-size: 15px;}

.profile-widget .user-name a {
  color: #373b3e; font-size: 16px; font-family: 'Poppins-Regular'; font-weight: 800; margin-top: 15px;
}

.avatar>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%
}

.avatar .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #1aa8fb;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center
}
.action-icon img{width: 20px;}
.hire td span{width: 74px;
  height: 34px;
  border-radius: 17px;
  background: #f5f5f5;display: inline-flex;
  justify-content: center;
  align-items: center;
  }
  .hire td span.or-n {
    background: #ffdcdc;
}

.hire td span.vi-n {
    background: #d6d0ff;
}

.hire td span.ye-n {
    background: #f7f7b4;
}

.hire td span.gr-n {
    background: #b4f7c2;
}
ul.bb li {
  font-size: 15px;
  margin-bottom: 10px;
  padding-left: 25px;
  background: url(../images/l-1.svg) no-repeat left center;
}
ul.gr li {
  font-size: 15px;
  margin-bottom: 10px;
  padding-left: 25px;
  background: url(../images/l-2.svg) no-repeat left center;
}
ul.ger li {
  font-size: 15px;
  margin-bottom: 10px;
  padding-left: 25px;
  background: url(../images/l-3.svg) no-repeat left center;
}
 
ul.de-p li.sop {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   width: 60%;
   padding-top: 0;
}
 
ul.de-p li.sop div.sop-pad div.dropdown.profile-action a.btn.btn-primary.dropdown-toggle img {
   display: none;
}
ul.de-p li {
   float: left;
   width: 40%;
}
ul.de-p li ul {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   column-gap: 2%;
   row-gap: 5px;
}
ul.de-p li ul li {
   flex: 0 1 49%;
   background: #fff;
   text-align: center;
   padding: 10px 0;
}
ul.de-p li ul li h3 {
   font-size: 16px;
   font-weight: 600;
   font-family: 'Poppins-Regular';
   margin: 0;
}
ul.de-p li ul li p {
   margin: 0;
   font-size: 14px;
   font-family: 'Poppins-Regular';
}
p.fw-bold.small {
  font-size: 12px;
}
ul.b-ul li .ta-lft.fw {
  flex: 0 1 100%;
}

ul.pagination li a {
  padding: 8px 12px;
  text-decoration: none;margin-left: -1px;position: relative;
  color: #222;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Poppins-Regular'; border: 1px solid #dee2e6;
}
ul.pagination li.active a{background: #0d6efd; color: #fff;  }
ul.pagination li:first-child a, ul.pagination li:last-child a{background: #ebebeb;    }

.table-bordered tr th a{text-decoration: none; color: #000;}
.m-cnt ul li.s-menu a{background: url(../images/arrow_down_icon.svg) no-repeat right 4px center;}
.m-cnt ul li.s-menu ul li a{background: none; border: none;padding:  6px 0 2px 30px; font-size: 13px;}
.m-cnt ul li.s-menu ul li:last-child a{border-bottom: 1px solid #397ADC; padding-bottom: 10px;}
.sel-new{width: 75px!important;}
.m-cnt ul li.s-menu ul li a.active{color: #3dd9eb; text-decoration: underline;}
/* .m-cnt ul li a.active {
  background: #0859d3 ;color: #3dd9eb;
} */
.m-cnt ul li.s-menu a.active {
  background: #0859d3  url(../images/arrow_down_icon.svg) no-repeat right 4px center;color: #3dd9eb;
}
.m-cnt ul li.s-menu ul li a.active{background-image: none;}
 

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
}
.loader .spinner-border{position: absolute;
  
  top: 50%;}

  /* 404 page  */
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body {
    padding: 0;
    margin: 0
}

#notfound {
    position: relative;
    height: 65vh
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.notfound {
    max-width: 460px;
    width: 100%;
    text-align: center;
    line-height: 1.4
}

.notfound .notfound-404 {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0 auto 50px
}

.notfound .notfound-404>div:first-child {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffa200;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 5px dashed #000;
    border-radius: 5px
}

.notfound .notfound-404>div:first-child:before {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    top: -5px;
    -webkit-box-shadow: 0 0 0 5px rgba(0,0,0,.1) inset;
    box-shadow: 0 0 0 5px rgba(0,0,0,.1) inset;
    border-radius: 5px
}

.notfound .notfound-404 h1 {
    font-family: cabin,sans-serif;
    color: #000;
    font-weight: 700;
    margin: 0;
    font-size: 90px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    text-align: center;
    height: 40px;
    line-height: 40px
}

.notfound h2 {
    font-family: cabin,sans-serif;
    font-size: 33px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 7px
}

.notfound p {
    font-family: cabin,sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 400
}

.notfound a {
    font-family: cabin,sans-serif;
    display: inline-block;
    padding: 10px 25px;
    background-color: #8f8f8f;
    border: none;
    border-radius: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: .2s all;
    transition: .2s all
}

.notfound a:hover {
    background-color: #2c2c2c
}
.form-label svg{color: #9b9b9b;}
#dropdown-basic img{width: 20px;
  height: 21px;
  border-radius: 100px;
  margin-right: 10px;
   }
   #dropdown-basic::after{top: 20px;
    position: absolute;}

    .breadcrumb li a{color: #000; text-decoration: none;}
    .breadcrumb li{color: #0455e7; text-decoration: none;}
.l-bll{background: #DFF1FF;}
.l-bll strong{    font-family: 'Poppins-Regular';
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  float: left;}
  .l-bll p{color: #024bd0;}

.ck-powered-by-balloon, .ck-powered-by {
  display: none;
}  

.welcome-cnt {width: 837px;display: flex;margin: 0 auto;flex-wrap: wrap; justify-content: center; padding: 0;}
.welcome-cnt .welcome-logo{width: 100%;float: left; text-align: center; margin-bottom: 20px;}
.welcome-cnt .welcome-logo img{mix-blend-mode: multiply;}
.welcome-cnt h2 {width: 100%;float: left;text-align: center; font-size: 40px; line-height: 45px; color: #000; font-family: 'Poppins-Bold'; margin-bottom: 12px;}
.welcome-cnt h2 span{color: #00A86B; display: block;}

  
.welcome-cnt p{width: 100%;float: left; text-align: center; font-family: 'Poppins-Regular'; color: #000; font-size: 18px; line-height: 24px; margin-bottom: 40px;}
.ideate-box {width: 837px;display: flex;flex-wrap: wrap;row-gap: 30px;justify-content: right; margin-bottom: 15px;}
.ideate-inner{ background: #f6f6f6; width: 837px; border-radius: 12px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; position: relative;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; text-align: right; border: 4px solid rgba(63,63,70,13%); position: relative; padding: 20px;}
/*.ideate-inner::before {content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;border-radius: inherit;padding: 2px;background: linear-gradient(to left, #FFB900, #F96815,#8031A7 );-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);mask-composite: add, add;mask-composite: exclude;-webkit-mask-composite: xor;}*/


.ideate-inner .form-control:focus {color: transparent;background-color: none;border-color: none;outline: 0;box-shadow: none;}
.btn.btn-primary.ideate-btn{font-size: 16px;line-height: 24px;padding: 8px 22px;font-family: 'Poppins-SemiBold';display: inline-flex;border-radius: 12px;text-decoration: none;background: rgba(119, 221, 119, 0.4); color: #000; border: none; height: 48px;column-gap: 7px; align-items: center; cursor: pointer; z-index: 999; position: relative; justify-content: center; min-width: 185px; transition: all .5s ease;}
.btn.btn-primary.ideate-btn span img {opacity: 0.79;}
.ideate-inner .btn.btn-primary.ideate-btn {position: relative;right: 10px;bottom: 0;}

.ideate-inner textarea{width: 100%; float: left; border: none; font-family: 'Poppins-Regular'; font-size: 16px; line-height: 25px; color: rgba(0, 0, 0, 0.73); background: transparent; resize: none;position: relative;z-index: 99; height: 80px; margin-bottom: 15px; overflow-y: auto;scrollbar-width: thin;scrollbar-color: #7d7 transparent; padding-right: 10px;}

.btn.btn-primary.ideate-btn.active-btn, .btn.btn-primary.ideate-btn:hover {background: #77DD77;}

.award-inner {width: 80%;display: flex;flex-wrap: wrap;row-gap: 15px; margin: 0px auto;}
.award-list{width: 92%;margin: 0 auto 30px;display: inline-flex;}
.award-title{width: 100%; float: left;}
.award-title h5{width: 100%;float: left;font-family: 'Poppins-SemiBold';font-size: 16px;line-height: 25px;color: #000;padding: 10px 12px;margin-bottom: 0;background: rgba(255,227,153,0.5);border-radius: 12px 12px 0 0;display: flex;column-gap: 10px;align-items: center;}
.award-title h5 span{width: 24px; height: 24px; background: #FFB900; border-radius: 100%; display: flex; align-items: center; text-align: center; justify-content: center; color: #fff;}
.award-cnt{width: 100%; float: left; border: 1px solid rgba(63,63,70,13%); padding: 30px 20px; background: #fff; border-radius: 12px;}
.award-cnt ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 15px; row-gap: 15px;justify-content: center;}
.award-cnt ul li { cursor: pointer; flex: 0 1 auto; border: 1px solid #A8A8A8; border-radius: 12px; font-size: 16px; line-height: 23px; font-family: 'Poppins-Regular'; display: flex; align-items: center; justify-content: center; padding: 8px 20px; transition: all .5s ease;
  -moz-transition: all .5s ease; -webkit-transition: all .5s ease; -o-transition: all .5s ease; box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 2%);}
.award-cnt ul li:hover { border: 1px solid rgba(119, 221, 119, 44%); background: rgba(119, 221, 119, 44%);}
.award-cnt ul li.active{background: rgba(119, 221, 119, 44%);font-family: 'Poppins-Medium'; border: 1px solid rgba(119, 221, 119, 44%); display: flex;align-content: center;column-gap: 10px;} 
.main-title{width: 100%;float: left;  font-family: 'Poppins-SemiBold'; font-size: 32px; line-height: 42px; text-align: center; margin-bottom: 25px;}
.main-title span{display: block;  font-family: 'Poppins-Regular';}
.award-rgt {width: calc(100% - 71px);}
.award-inner.employe {width: 90%;}

.edit-sec{  width: 100%;float: left;margin-bottom: 20px;height: 78px;align-items: center;display: flex;padding: 0 20px;font-family: 'Poppins-Medium';font-size: 16px;line-height: 25px;border-radius: 10px;background: #f6f6f6;position: relative;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;z-index: 1; border: 4px solid rgba(63,63,70,13%);}
.edit-inner {flex: 0 1 calc(100% - 55px); overflow-x: hidden; overflow-y: auto;scrollbar-width: thin;scrollbar-color: #00a86b transparent; height: 50px;padding-right: 10px; display: flex; align-items: center;}
.edit-sec input{padding: 0;font-family: 'Poppins-Medium';font-size: 16px;line-height: 25px; width: calc(100% - 60px); float: left; border: none; background: #f6f6f6;}
/*.edit-sec::before {content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;border-radius: inherit;padding: 2px;background: linear-gradient(to left, #FFB900, #F96815,#8031A7 );-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);mask-composite: add, add;mask-composite: exclude;-webkit-mask-composite: xor;z-index: -1;}*/
.edit-sec span {width: 40px;height: 40px;background: #fff;border-radius: 100px;align-items: center;display: inline-flex;justify-content: center;position: absolute;right: 20px;top: 50%;transform: translateY(-50%); cursor: pointer;}
.show-btn {width: 100%;float: left; justify-content: center; display: flex; flex-wrap: wrap; column-gap: 20px; padding: 15px 0;}
.border-0.p-15.card {background: #fbfbfb; width: 100%;float: left; border-radius: 0 0 15px 15px;}
.award-inner-pad {width: 100%;float: left;display: flex;flex-wrap: wrap;}
.show-btn .skip-btn {display: flex; align-items: center; padding: 8px 22px; font-family: 'Poppins-SemiBold';color: #000;font-size: 16px;line-height: 24px;border: 1px solid #000;background: #fff;border-radius: 12px;height: 48px;transition: all 0.5s ease;}
.show-btn .skip-btn:hover {background: #00A86B;border: 1px solid #00A86B;color: #fff;}
.main-lft { width: 100%; /*height: 100vh;*/z-index: 1; position: relative; padding-right: 0;}

.subject-sec{width: 100%; float: left; display: flex;flex-wrap: wrap;row-gap: 30px;}
.tone-lst{width: 100%; float: left; display: flex; column-gap: 8px;}
.tone-lst h3{width: 50px; color: #747474; font-size: 12px; line-height: 20px; letter-spacing: 2px; font-family: 'Poppins-Regular'; text-transform: uppercase; margin-bottom: 0; padding-top: 11px;}
.tone-lst ul{width: calc(100% - 55px); display: flex;flex-wrap: wrap;column-gap: 8px;row-gap: 10px;}
.tone-lst ul li{cursor: pointer;flex: 0 1 auto;border: 1px solid #D6D6D6;border-radius: 8px;font-size: 15px;line-height: 21px; letter-spacing: -2%; font-family: 'Poppins-Regular';display: flex;align-items: center;justify-content: center;padding: 8px 10px;transition: all .5s ease;-moz-transition: all .5s ease;-webkit-transition: all .5s ease;-o-transition: all .5s ease;}
.tone-lst ul li.active, .tone-lst ul li:hover {border: 1px solid rgba(119, 221, 119, 44%);background: rgba(119, 221, 119, 44%);}
.subject-cnt{width: 100%; float: left; padding-right: 15px;}
.subject-cnt h6{width: 100%; float: left; color: #000; font-family: 'Poppins-SemiBold'; font-size: 20px; line-height: 24px; letter-spacing: 0px; margin-bottom:20px;}
.subject-cnt h5{width: 100%; float: left; font-family: 'Poppins-Regular'; color: #000; font-size: 16px; line-height: 25px; margin-bottom: 15px;}
.subject-cnt p{width: 100%; float: left; color: #000; font-family: 'Poppins-Regular'; font-size: 16px; line-height: 25px;}
.subject-cnt p span{font-family: 'Poppins-SemiBold';}
.show-rgt {display: flex;column-gap: 10px;}

.btn.btn-primary.share-btn{font-size: 13px;line-height: 22px;color: #000;font-family: 'Poppins-SemiBold';padding: 0px;display: inline-flex;align-items: center;justify-content: left;text-decoration: none;
background: transparent;transition: all 0.5s ease;position: relative;border-radius: 12px; border: none;}
.btn.btn-primary.share-btn:active {border-color: transparent;background-color: transparent;color: #000;}
.btn.btn-primary.share-btn span {column-gap: 7px;display: flex;align-items: center;  font-family: 'Poppins-Regular';}
.btn.btn-primary.share-btn span img {width: 15px;}

.btn.btn-primary.share-btn:hover svg{opacity: 0.5;}
.btn.btn-primary.go-back:hover span svg fill{color: #fff !important;}
.show-btn.full-width {flex-wrap: wrap;row-gap: 22px; width: 95%;}
.btn.btn-primary.go-back .svg-inline--fa.fa-chevron-left{color: #000 !important;}
.btn.btn-primary.go-back:hover .svg-inline--fa.fa-chevron-left{color: #fff !important;}
.show-rgt.full-width {width: 100%;text-align: right;display: flex;flex-wrap: wrap;column-gap: 20px;justify-content: space-between; align-items: center;}
.show-lft.full-width {width: 100%; float: left; justify-content: space-between; display: flex; flex-wrap: wrap;}
.rate-mail{width: auto;}
.rate-mail ul{width: 100%; display: flex; column-gap: 5px; align-items: center;}
.rate-mail ul li{flex: 0 1 auto; font-family: 'Poppins-Regular'; color: #000; font-size: 16px; line-height: 25px; }
.rate-mail ul li:first-child {margin-right: 12px;}
.rate-mail ul li span{cursor: pointer; width: 32px;height: 32px;display: flex;background-size: cover !important; transition: all .5s ease;}
.rate-mail ul li.icon1 span{background: url("../images/face-icon1.svg") no-repeat 0 0;}
.rate-mail ul li.icon2 span{background: url("../images/face-icon2.svg") no-repeat 0 0;}
.rate-mail ul li.icon3 span{background: url("../images/face-icon3.svg") no-repeat 0 0;}
.rate-mail ul li.icon4 span{background: url("../images/face-icon4.svg") no-repeat 0 0;}
.rate-mail ul li.icon5 span{background: url("../images/face-icon5.svg") no-repeat 0 0;}
.rate-mail ul li.icon1.active span{background: url("../images/face-icon1-h.svg") no-repeat 0 0;}
.rate-mail ul li.icon2.active span{background: url("../images/face-icon2-h.svg") no-repeat 0 0;}
.rate-mail ul li.icon3.active span{background: url("../images/face-icon3-h.svg") no-repeat 0 0;}
.rate-mail ul li.icon4.active span{background: url("../images/face-icon4-h.svg") no-repeat 0 0;}
.rate-mail ul li.icon5.active span{background: url("../images/face-icon5-h.svg") no-repeat 0 0;}

/* Scroll Style Start Here */
.scroll-div { float: left; width: 100%; height: 377px !important;}
.prompt-content-edit-sec .content .scroll-div { float: left; width: 100%; height: 100% !important;}
.edit-sec .edit-inner .scroll-div { float: left; width: 100%; height: 50px !important;scrollbar-color: #7d7 transparent;padding-right: 10px;}
.edit-sec .edit-inner .scroll-div>div { display: flex;flex-wrap: wrap;align-items: center; }
.edit-sec .edit-inner .scroll-div .scroll-div-inner { padding-right: 15px; }

/* mythology page css */ 
.mythology-sec { float: left; width: 100%;}
.mythology-sec .myth-lft { float: left; width: 225px; height: 100%; /*padding-bottom: 50px;*/ /*position: fixed; top: 88px; overflow: hidden; overflow-y: auto;*/ }
.mythology-sec .myth-rgt { float: right; width: calc(100% - 225px); padding-left: 20px;}
.myth-sidebar { float: left; width: 100%; display: flex; flex-wrap: wrap; row-gap: 40px; background: #F4F5F7; border-radius: 0px; padding: 15px 8px 10px; height: 100%; align-content: flex-start;}
.myth-sidebar > div { flex: 0 1 100%; width: 100%;}
.myth-sidebar h4 { float: left; width: 100%; color: #747474; font-family: 'Poppins-Regular'; font-size: 12px; line-height: 18px; padding-bottom: 0px; margin: 0; letter-spacing: 1px; text-transform: uppercase;}
.myth-sidebar ul { float: left; width: 100%; display: flex; flex-wrap: wrap; row-gap: 10px;}
.myth-sidebar ul li { z-index: 1; flex: 0 1 100%; column-gap: 10px; display: flex; align-items: center; justify-content: space-between; padding: 10px; color: #000; font-size: 12px; line-height: 18px;  font-family: 'Poppins-Medium'; border-radius: 4px; position: relative; border: 1px solid #E8E8E8; background: #fff; box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 2%);}
.myth-sidebar ul li.removed { background-color: #f8d7da; border: 1px solid #f1aeb5; color: #b71b1b;}
.myth-sidebar ul li.removed svg path { fill: #c71a1a; }
.myth-sidebar ul li span { cursor: pointer;}
/*.myth-sidebar ul li::after { z-index: -1; content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;border-radius: inherit;padding: 2px; background: linear-gradient(to left, #FFB900, #F96815,#8031A7); -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); mask-composite: add, add;mask-composite: add, add;mask-composite: exclude; -webkit-mask-composite: xor;transition: all 0.5s ease;}*/
.myth-list-sec { float: left; width: 100%; border: 1px solid #E1E1E1; border-radius: 12px; overflow: hidden; margin-bottom: 25px; margin-left: 1px; }
.myth-title { float: left; width: 100%; background: rgba(255, 227, 153, 0.50); padding: 15px;}
.myth-title h3 { float: left; width: 100%; color: #747474; font-size: 12px; line-height: 18px; margin: 0; padding-bottom: 5px; letter-spacing: 2px;}
.myth-submenu { display: none; position: absolute; top: 30px; left: 0; z-index: 1; background: #FFF; width: 180px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);}
.myth-subtitle.active .myth-submenu { display: block;}
.myth-subtitle.active span { transform: rotate(180deg);}
.myth-submenu ul { float: left; width: 100%; display: flex; flex-wrap: wrap;}
.myth-submenu ul li { flex: 0 1 100%; border-bottom: 1px solid #DFDFDF;}
.myth-submenu ul li:last-child { border-bottom: none;}
.myth-submenu ul li a { color: #000000; padding: 8px 10px; font-size: 14px; line-height: 21px; font-family: 'Poppins-Regular'; transition: all 0.2s ease-in;}
.myth-submenu ul li a:hover { background: #F96815; color: #FFFFFF;}
.myth-subtitle { float: left; width: 100%; position: relative;}
.myth-subtitle a { display: flex; flex-wrap: wrap; align-items: center; column-gap: 8px; text-decoration: none;}
.myth-subtitle h2 { float: left; width: auto;font-family: 'Poppins-Bold'; color: #000000; font-size: 18px; line-height: 24px; padding: 0; margin: 0;}
.myth-subtitle span { background: url("../images/myth-arrow.svg") no-repeat right center; width: 23px; height: 23px; display: block; position: relative; top: 2px;}
.myth-list { float: left; width: 100%; padding: 15px;}
.recognition-card { background-color: #ffffff; border: 1px solid #D5D5D5; border-radius: 8px; padding: 20px; margin-top: 10px; position: relative; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); display: flex; flex-wrap: wrap;}
.recognition-title { margin-bottom: 10px;}
.recognition-title span { background: url("../images/medal-img.png") no-repeat right center; width: 20px; height: 21px; display: block;}
.recognition-content { width: 65%; padding-right: 30px;}
.recognition-content p {color: #595555; font-size: 14px; line-height: 21px; font-family: 'Poppins-Regular'; margin: 0; }
.characteristics { background: #EEEEEE; width: 35%; border-radius: 8px; padding: 15px 10px; border: 1px solid #E1E0E0;}
.characteristics-title { display: flex; align-items: center; column-gap: 5px; margin-bottom: 5px;}
.characteristics-title h3 { font-family: 'Poppins-Regular'; color: #747474; font-size: 12px; line-height: 18px; letter-spacing: 2px; padding: 0; margin: 0;}
.characteristics-title span { opacity: 1; background: url("../images/info-icon.svg") no-repeat right center; width: 12px; height: 12px; display: block;}
.characteristics-tags { display: flex; flex-wrap: wrap; column-gap: 10px; row-gap: 10px;}
.characteristic-tag { transition: all 0.2s ease-in; background-color: #FFFFFF; color: #000000; font-size: 14px; font-family: 'Poppins-Regular'; border: 1px solid #EEEEEE; padding: 5px 10px; border-radius: 8px; cursor: pointer;}
.characteristic-tag.active { border: 1px solid #F96815; color: #F96815;}
.characteristic-tag:hover { border: 1px solid #F96815; color: #F96815;}
.myth-list ul li { padding-left: 30px; position: relative;}
.myth-list ul li [type="radio"]:not(:checked) {position: absolute;left: 0; visibility: hidden;}
.myth-list ul li [type="radio"]:checked, .myth-list ul li [type="radio"]:not(:checked) { position: absolute; left: 0; visibility: hidden;}
.myth-list ul li [type="radio"]:checked + .recognition-card label, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label { position: relative; padding-left:0px; cursor: pointer; line-height: 20px; display: inline-flex; column-gap: 5px; color: #000000;  font-family: 'Poppins-SemiBold'; font-size: 16px; margin-right:0px; }
.myth-list ul li [type="radio"]:checked + .recognition-card label:before, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label:before { content: ''; position: absolute; left: -50px; top:0px; width: 20px; height: 20px; border: 1px solid #F96815; background: #fff; margin:2px 13px 0 0; border-radius: 25px;}
.myth-list ul li [type="radio"]:checked + .recognition-card label:after, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label:after {content: ''; width: 12px; height: 12px; background: #F96815; position: absolute; top: 6px; left: -46px; -webkit-transition: all 0.2s ease; transition: all 0.2s ease;  border-radius: 25px;}
.myth-list ul li [type="radio"]:not(:checked) + .recognition-card label:after { opacity: 0; -webkit-transform: scale(0); transform: scale(0);}
.myth-list ul li [type="radio"]:checked + .recognition-card label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
.myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::before {border: 1px solid #D5D5D5;}
.myth-list ul li [type="radio"]:checked + .recognition-card { background-color: #fdfdfd; border: 1px solid #F96815; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);}
.tooltip { position: relative; display: inline-block; cursor: pointer;}
.tooltip::after { content: attr(data-tooltip); position: absolute; bottom: 180%; left: 50%; transform: translateX(-50%); background-color: #333; color: #fff; padding: 5px; border-radius: 4px; white-space: nowrap; opacity: 0; visibility: hidden; transition: opacity 0.3s; font-size: 12px; z-index: 1; pointer-events: none;}
.tooltip::before { content: ''; position: absolute; bottom: 100%; left: 50%; transform: translateX(-50%); border-width: 5px; border-style: solid; border-color: #333 transparent transparent transparent; opacity: 0; visibility: hidden; transition: opacity 0.3s; z-index: 1;}
.tooltip:hover::after, .tooltip:hover::before { opacity: 1; visibility: visible;}

.plot-cards { float: left; width: 100%; padding: 0px 0px 15px 0px; }
.plot-cards ul { float: left; width: 100%; display: flex; flex-wrap: wrap; column-gap: 1.8%; row-gap: 15px; align-items: center; text-align: center;}
.plot-cards ul li { flex: 0 1 32%; border: 1px solid #d5d5d5; border-radius: 10px; list-style: none; }
.plot-cards ul li .plot-list {padding: 20px; display: flex; flex-wrap: wrap; align-items: center; align-content: center; justify-content: center; text-align: center; min-height: 182px; }
.plot-cards ul li .plot-list span {background: url("../images/medal-img.png") no-repeat right center; width: 20px; height: 21px; display: block; margin-bottom: 15px; min-height: 25px;}
.plot-cards ul li h3 { float: left; width: 100%; font-family: 'Poppins-SemiBold'; padding: 0 30px; font-size: 18px; line-height: 21px; color: #000000; margin-bottom: 20px;}

/*drop-down*/
.main-menu{width: auto;display: flex;column-gap: 20px;align-items: center;}
.main-menu ul{float: left; width: 72px; display: flex; flex-wrap: wrap; column-gap: 30px; row-gap: 0; text-decoration: none; position: relative;}
.main-menu ul > li{width: 100%; float: left; position: relative; display: flex; flex-wrap: wrap; column-gap: 15px; align-items: center;}
.main-menu ul > li strong{display: block;}
.main-menu ul li ul.dropdown{display: none; column-gap: 0;row-gap: 0;position: absolute;top: 55px;transition: all 0.5s ease;border-radius: 8px;width: 100%;float: left;min-width: max-content; right: 10px;margin: 0 auto; flex-wrap: wrap; background: #fff; border: 1px solid #A8A8A8;  border-radius: 8px; padding: 12px 14px !important;}
.main-menu ul li ul.dropdown li{ float: left; width: 100%; display: flex; flex-wrap: wrap; font-size: 14px; line-height: 21px; font-family: 'Poppins-Regular'; word-break: break-all; text-align: center;justify-content: center;}
.main-menu ul li ul.dropdown li { flex: 0 1 100%;}
.main-menu ul li ul.dropdown li a {width: 100%; color: #000000; background: transparent; font-size: 14px; line-height: 21px; font-family: 'Poppins-Regular'; border-radius: 0; justify-content: left; transition: all 0.2s ease-in; width: auto; height: auto;}
.main-menu ul li ul.dropdown li a:hover { background: none; color: #00a86b;}
.main-menu ul > li.active ul.dropdown{display: flex; row-gap: 8px;}
.main-menu ul li ul.dropdown li a.sign-out {width: 100%; background: #F2F2F2;font-size: 14px;line-height: 21px; font-family: 'Poppins-Regular'; display: flex; column-gap: 10px; align-items: center; justify-content: center; text-align: center; height: 41px; border: 1px solid rgba(168, 168, 168, 20%); border-radius: 8px; transition: all 0.5s ease;}
.main-menu ul li ul.dropdown li a.sign-out strong{background: url("../images/signout.png") no-repeat 0 0; width: 18px; height: 17px;}
.main-menu ul li ul.dropdown li a.sign-out:hover{background: #00a86b; color: #fff; border: 1px solid #00a86b;}
.main-menu ul li ul.dropdown li a.sign-out:hover strong{background: url("../images/signout-h.png") no-repeat 0 0;}


.main-menu ul li a span{position: absolute;top: 50%;right: 0px;transform: translateY(-50%);width: 22px;height: 22px;}
.main-menu ul li a span:after{position: absolute;content: "";left: 0;right: 0px; top: 5px;background: none;width: 12px;height: 12px;border-top: 3px solid #000;border-left: 3px solid #000;transition: all 0.5s ease;transform: rotate(220deg); margin: 0 auto;}
.main-menu ul li.active a span:after{transform: rotate(45deg); top: 8px;}
/*.main-menu ul li .n-icon{background: #D8D8D8; width: 38px; height: 38px; border-radius: 100%; display: flex;align-items: center;justify-content: center;}*/
.main-menu ul li .n-cnt{width: 100%; position: relative;}
.main-menu ul li .n-cnt a{font-family: 'Poppins-SemiBold'; color: #000; text-decoration: none; font-size: 16px; background: #59CBE8;width: 40px;height: 40px;display: inline-flex;align-items: center;justify-content: center;border-radius: 100%; transition: all 0.5s ease;}
.main-menu ul li .n-cnt a strong{font-weight: normal; font-family: 'Poppins-Regular';}

.main-menu ul li .n-cnt a:hover {background: #00a86b; color: #fff;}

.notification{background: #D8D8D8; width: 48px; height: 48px; border-radius: 50px; display: flex;align-items: center;justify-content: center; position: relative; cursor: pointer;}
.notification .number {background: #ffb900;width: 22px; height: 22px;position: absolute; right: 0px; top: -10px; border-radius: 20px; display: flex;align-items: center;justify-content: center;color: #000;font-family: 'Poppins-Medium';}
.btn.btn-primary.copy{ line-height: 24px;padding: 0px 22px;display: inline-flex;align-items: center;justify-content: center;height: 48px;min-width: 130px;font-family: 'Poppins-SemiBold', sans-serif;border-radius: 12px;text-decoration: none;border: none;border-image-source: linear-gradient(to left, #8031A7, #FFB900);background: linear-gradient(to right, rgba(128,49,167,1) 0%, rgba(249,105,21,1) 50%, rgba(255,187,0,1) 100%);color: #fff;transition: all 0.5s ease;position: relative;}
.btn.btn-primary.copy::after { content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;border-radius: inherit;padding: 2px;border: linear-gradient(to left, #FFB900, #F96815,#8031A7);-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);mask-composite: add, add;mask-composite: add, add;mask-composite: exclude;-webkit-mask-composite: xor;z-index: -1;}
.btn.btn-primary.copy:hover{background: rgba(255,187,0,1);background: -moz-linear-gradient(left, rgba(255,187,0,1) 0%, rgba(249,105,21,1) 50%, rgba(128,49,167,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,187,0,1)), color-stop(50%, rgba(249,105,21,1)), color-stop(100%, rgba(128,49,167,1)));
  background: -webkit-linear-gradient(left, rgba(255,187,0,1) 0%, rgba(249,105,21,1) 50%, rgba(128,49,167,1) 100%);
  background: -o-linear-gradient(left, rgba(255,187,0,1) 0%, rgba(249,105,21,1) 50%, rgba(128,49,167,1) 100%);
  background: -ms-linear-gradient(left, rgba(255,187,0,1) 0%, rgba(249,105,21,1) 50%, rgba(128,49,167,1) 100%);
  background: linear-gradient(to right, rgba(255,187,0,1) 0%, rgba(249,105,21,1) 50%, rgba(128,49,167,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbb00', endColorstr='#8031a7', GradientType=1 ); color: #fff;}
.btn.btn-primary.idea-btn span{background: none; margin-bottom: 0;min-height: inherit;}

.notification:hover .box {display: block;}

.main-menu .box {width: 300px;height: 0px;border-radius: 10px;transition: 0.5s;position: absolute;left: -125px; bottom: 3px;background-color: #F4F4F4;box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.1);cursor: context-menu; display: none;}
.main-menu .box ul{width: 100%; float: left; display: flex; flex-wrap: wrap; padding: 0px !important;}
.main-menu .box ul li a{flex: 0 1 100%; display: flex; column-gap: 15px; align-items: center; color: #000; text-decoration: none; padding: 12px !important; font-family: 'Poppins-Regular'; font-size: 14px; line-height: 22px; border-bottom: 1px solid #D6D6D6; transition: all 0.5s ease;}
.main-menu .box ul li a:hover {color: #F96815;}
.main-menu .box ul li .box-img{width: 60px; height: 60px; background: #D8D8D8; border-radius: 50px;}
.main-menu .box ul li .box-txt{width: calc(100% - 75px);}
.main-menu .box ul li:last-child a{border-bottom: none;}

.btn-inner-go-back { width: 100%; float: left; margin-top: 15px; }
.btn-inner-go-back .inner-go-back { font-size: 14px;line-height: 24px;color: #747474;font-family: 'Poppins-Regular';display: inline-flex;align-items: center;justify-content: center;  text-decoration: none;
  border: none;
  background: transparent;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 12px;
  column-gap: 5px; font-weight: normal;padding: 0px; margin: 0px; }
.btn-inner-go-back .inner-go-back:hover { background: none;color: #F96815; }

.btn-inner-go-back .inner-go-back .svg-inline--fa.fa-chevron-left {
  color: #747474 !important;
}

.btn-inner-go-back .inner-go-back:hover .svg-inline--fa.fa-chevron-left {
  color: #F96815 !important;
}

.prompt-content-edit-sec { width: 100%;float: left;margin-bottom: 50px;height: 128px;align-items: center;font-family: 'Poppins-Medium';font-size: 16px;line-height: 25px;border-radius: 12px;background: #f6f6f6;position: relative;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;z-index: 1; border: 4px solid rgba(63,63,70,13%);}
.prompt-content-edit-sec textarea, .prompt-content-edit-sec input{width: calc(100% - 80px); float: left; border: none; font-family: 'Poppins-Regular'; font-size: 16px; line-height: 25px; color: rgba(0, 0, 0, 0.8); background: transparent; resize: none;position: relative;z-index: 99; height: 100%;padding: 20px;}
/*.prompt-content-edit-sec::before {content: "";position: absolute;top: 0;left: 0;right: 0;bottom: 0;border-radius: inherit;padding: 2px;background: linear-gradient(to left, #FFB900, #F96815,#8031A7 );-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);mask-composite: add, add;mask-composite: exclude;-webkit-mask-composite: xor;z-index: -1;}*/
.prompt-content-edit-sec span {width: 40px;height: 40px;background: #fff;border-radius: 100px;align-items: center;display: inline-flex;justify-content: center;position: absolute;right: 20px;top: 50%;transform: translateY(-50%); cursor: pointer;}
.prompt-content-edit-sec .content { width: calc(100% - 80px); padding: 20px;font-family: 'Poppins-Regular'; font-size: 16px; line-height: 25px; color: rgba(0, 0, 0, 0.8); background: transparent; height: 100%;}

.refresh {display: flex;justify-content: right;}
.refresh .btn.btn-primary.share-btn {width: 28px;height: 28px;min-width: 28px;border-radius: 5px;background: #d8d8d8;border: 1px solid #d8d8d8; cursor: auto; transition: all .5s ease; display: flex;align-items: center;justify-content: center;}
.refresh .btn.btn-primary.share-btn.active, .refresh .btn.btn-primary.share-btn:hover {background: #7d7; border: 1px solid #7d7; cursor: pointer;}
.refresh .btn.btn-primary.share-btn.active path, .refresh .btn.btn-primary.share-btn:hover path {fill: #fff;}
.myth-sidebar-title { width: 100%; display: flex;justify-content: space-between;align-items: center;}
.myth-sidebar-inner {width: 100%; display: flex; flex-wrap: wrap;row-gap: 15px;}
.myth-sidebar-inner p { width: 100%;padding-top:20px;color: #000000;}
.myth-sidebar ul li.myth-sidebar-culture {flex-wrap: wrap;background: #fff1cc;padding: 10px; row-gap: 10px;}
.myth-sidebar ul li.myth-sidebar-culture:after{display: none;}
.myth-sidebar ul li.myth-sidebar-culture div{transition: all 0.2s ease-in;color: #F96815;font-size: 16px;font-family: 'Poppins-Regular';cursor: pointer; width: 100%;display: flex;justify-content: space-between;align-items: center;}
.myth-sidebar ul li.myth-sidebar-culture span{display: flex; flex-wrap: wrap; column-gap: 0; row-gap: 10px;}
.myth-sidebar ul li.myth-sidebar-culture span ul{display: flex;flex-wrap: wrap;column-gap: 10px;row-gap: 10px;}
.myth-sidebar ul li.myth-sidebar-culture span ul li{transition: all 0.2s ease-in;background-color: #FFFFFF;color: #F96815;font-size: 14px;font-family: 'Poppins-Regular';border: 1px solid #F96815;padding: 5px 10px;border-radius: 8px;cursor: pointer; flex: 0 1 100%;display: flex;justify-content: space-between;align-items: center;} 
.myth-sidebar ul li.myth-sidebar-culture span ul li::after{display: none;}

.prompt-title { width: 100%; float: left;}
.prompt-title h3 {width: 100%; float: left; font-family: 'Poppins-Bold';font-size: 18px;line-height: 28px; letter-spacing: 0.5px; color: #000;padding: 0px;margin: 0 0 10px 0;}
.mythology-sec .myth-rgt .scroll-div{padding-right: 30px;}
.mythology-sec .myth-rgt .scroll-div .myth-list-sec{width: calc(100% - 20px);}

.cursor-pointer { cursor: pointer; }
.cursor-pointer-non { cursor: auto !important;background:none !important; }

.myth-sidebar ul li.myth-sidebar-culture div.width-none { width: 15px;}


.ideate-list {width: 837px;display: flex;flex-wrap: wrap;}
.ideate-list p{width: 100%; float: left; text-align: left; color: #747474; font-family: 'Poppins-Regular'; font-size: 14px; line-height: 24px; margin-bottom: 12px;}
.ideate-list ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 2%; row-gap: 15px;}
.ideate-list ul li { cursor: pointer; flex: 0 1 23.5%; background: #fff; border: 2px solid rgba(63,63,70,13%); border-radius: 12px; font-size: 14px; line-height: 22px; color: #000; font-family: 'Poppins-Regular'; display: flex; align-items: center; justify-content: center; padding: 12px 12px; transition: all .5s ease;
  -moz-transition: all .5s ease; -webkit-transition: all .5s ease; -o-transition: all .5s ease;}
.ideate-list ul li:hover { border: 2px solid #77DD77; color: #000; background: #77DD77;}
.ideate-list ul li.active{border: 2px solid #77DD77; color: #000; background: #77DD77; display: flex;align-content: center;column-gap: 10px;}

.generated-email{width: 100%; float: left; padding: 45px 45px;}
.generated-email h2{width: 100%; float: left; color: #000; font-family: 'Poppins-Medium'; font-size: 32px; line-height: 36px; text-align: center; margin-bottom: 45px;}
.generated-email h2 span{display: block;}
.generated-email ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 2%; row-gap: 0;}
.generated-email ul li{flex: 0 1 18%;}
.generated-email ul li .e-icon{width: 68px; height: 68px; border-radius: 100%; background: #EBEDEF; display: flex; align-items: center; justify-content: center; margin: 0 auto; cursor: pointer;}

.generated-btn {width: 100%;float: left; margin-top: 45px;}
.generated-btn ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 2%; row-gap: 0;}
.generated-btn ul li{flex: 0 1 49%;}
.generated-btn ul li .cancel-btn{width: 100%; font-family: 'Poppins-SemiBold'; color: #000; font-size: 16px; line-height: 24px; border: 1px solid #000; background: #fff; border-radius: 12px; height: 48px; transition: all 0.5s ease;cursor: pointer;}
.generated-btn ul li .cancel-btn:hover{background: #7D7; border: 1px solid #7D7; color: #000;}
.generated-btn ul li .submit-btn{width: 100%; font-family: 'Poppins-SemiBold'; color: #000; font-size: 16px; line-height: 24px; border: 1px solid #000; background: #fff; border-radius: 12px; height: 48px; transition: all 0.5s ease; cursor: pointer;}
.generated-btn ul li .submit-btn:hover{background: #7D7; border: 1px solid #7D7; color: #000; opacity: 1;}
.generated-btn ul li .disable-btn {width: 100%; font-family: 'Poppins-SemiBold'; color: #000; font-size: 16px; line-height: 24px; border: 1px solid #dadada; background: #dadada; border-radius: 12px; height: 48px; transition: all 0.5s ease;}
.generated-email .form-control {height: 180px; border: 1px solid #81858B; font-family: 'Poppins-Regular'; padding: 20px;}

.generated-popup{width: 100%;float: left; position: relative;}
.generated-popup .modal-header{border-bottom: none; padding: 0;}              
.generated-popup .modal-body{padding: 0;} 
.btn-close{ position: absolute; top:30px; right: 15px; z-index: 99;} 
.generated-popup .form-label{font-size: 24px; line-height: 36px; color: #000; font-family: 'Poppins-Medium'; font-weight: normal; float: left; margin-bottom: 15px; width: 100%;text-align: center;}
.generated-popup .feedbacktop-logo {width: 100%;margin-bottom: 12px;text-align: center;}
.custom-dialog { width: 90%; }


/*login*/
.login-form{width: 100%;float: left; position: relative; padding: 45px 65px 30px;}
.login-pad {width: 100%;float: left;padding: 0 55px;}
.modal-header{border-bottom: none !important; padding: 0 !important;}
.login-form .modal-body{padding: 0;}
.btn-close{ position: absolute; top:35px; right: 35px; z-index: 99;}
.login-form h2{width: 100%; float: left; color: #000; font-family: 'Poppins-Medium'; font-size: 26px; line-height: 32px; text-align: center; margin-bottom: 30px;}
.popup-logo {width: 100%;float: left;text-align: center;margin-bottom: 10px;}
.popup-logo img{margin: 0px auto; width: 80px;}

.login-form .form-box{width: 100%;float: left;margin-bottom: 20px; display: flex;flex-wrap: wrap;row-gap: 20px;}
.login-form .form-box .frm-input .form-control {height: 48px;border: 1px solid #63666A;border-radius: 8px;}
.login-form .form-box .frm-input {float: left;width: 100%;position: relative;}
.login-form .form-box .frm-input label{font-family: 'Poppins-Regular';font-size: 14px;color: #5E6368;float: left;margin-bottom: 8px;}
.login-form .form-box .frm-input label sup { color: red;font-size: 14px;line-height: 16px;font-family: 'Poppins-Regular'; }
.login-form .form-box input[type="email"] {width: 100%;color: #000;padding: 10px 15px 10px;height: 48px;border: 1px solid #63666A; font-family: 'Poppins-Regular'; font-size: 16px;transition: all .5s ease;background: #fff;line-height: 24px;}
.login-form .form-box .frm-input .form-control:focus {box-shadow: none !important;}
.login-form .floating-label { position: relative;}
.login-form .floating-label {position: absolute;pointer-events: none;top: -10px;left: 6px;transition: all 0.5s ease; background: #fff;padding: 0 10px;font-size: 12px;color: #5E6368;}
.login-form .floating-label sup{color: red; font-size: 14px; line-height: 16px; font-family: 'Poppins-Regular';}
.verification-box{width: 100%; float: left; /*margin-bottom: 25px;*/}
.verification-box label{width: 100%; float: left; color: #5E6368; font-size: 12px; line-height: 16px; font-family: 'Poppins-Regular'; margin-bottom: 12px;}
.verification-box label sup{color: red; font-size: 14px; line-height: 16px; font-family: 'Poppins-Regular';}
.verification-box ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 4%; row-gap: 0;margin-bottom: 10px !important;}
.verification-box ul li{flex: 0 1 22%;}
.verification-box ul li input[type="text"] {color: #00A86B;padding: 10px 10px 10px 10px; width: 100%; height: 48px;border: 1px solid #63666A; border-radius: 8px; font-family: 'Poppins-SemiBold'; font-size: 20px;background: #FAFAFA;line-height: 28px; text-align: center;}
.verification-box p{color: #5E6368; font-size: 14px; line-height: 24px; font-family: 'Poppins-Regular'; float: left;width: 100%;margin-bottom: 0px;margin-top: 5px; text-align: left;}
.verification-box p span{color: #000;font-family: 'Poppins-Medium';}
.verification-box p a {color: #005EB8;text-decoration: underline;cursor: pointer;transition: all 0.5s ease;}
.verification-box p a:hover {color: #00A86B;}
.verification-box ul li input[type="text"].is-null { padding-top: 11px; padding-bottom: 2px;font-size: 24px; }

.btn.login-btn{width: 100%; background: #77DD77;padding: 15px;font-family: 'Poppins-SemiBold'; font-size: 16px; line-height: 24px;border-radius: 12px;display: inline-flex;align-items: center; text-align: center;justify-content: center;transition: all 0.5s ease;}
.btn.login-btn:hover{background: #C3F0C2; color: #000;}

.or-txt{width: 100%; float: left; margin: 15px 0; position: relative; text-align: center; position: relative;}
.or-txt::after{position: absolute; content: ""; left: 0; top:50%; transform: translateY(-50%); width: 100%; height: 1px; background: #DADADA;}
.or-txt span{color: #63666A; font-size: 18px; line-height: 28px; font-family: 'Poppins-Regular'; background: #fff; padding: 0 20px; position: relative; z-index: 9;}

.here-btn{width: 100%; float: left; text-align: center;}
.here-btn p{color: #63666A; font-size: 14px; line-height: 24px; font-family: 'Poppins-Regular'; display: flex;column-gap: 5px;justify-content: center; margin-bottom: 0;}
.here-btn p a{color: #00A86B; text-decoration: underline; cursor: pointer;transition: all 0.5s ease;}
.here-btn p a:hover{color: #00A86B; text-decoration: none;}
.btn.gle-btn{width: 100%; padding: 12px 12px; float: left; display: flex; align-items: center; justify-content: center; column-gap: 15px; border: 1px solid #DADADA; border-radius: 12px; color: #005EB8; font-family: 'Poppins-Medium'; font-size: 16px; line-height: 26px; margin-bottom: 10px; cursor: pointer; transition: all 0.5s ease;}
.btn.gle-btn:hover {background: #77DD77;}


.login-form .form-box .frm-input.checkbox { float: left; width: 100%; margin-bottom: 0; } 
.login-form .form-box .frm-input.checkbox input {  height: 1px; left: 0; position: absolute; top: 0; visibility: hidden; width: 1px;}
.login-form .form-box .frm-input.checkbox label {color: #2B2C2E;cursor: pointer;font-size: 12px;padding-left: 30px;position: relative;float: left;width: auto;line-height:27px;text-align:left;font-family: 'Poppins-Regular';}
.login-form .form-box .frm-input.checkbox label::before { background: #fff; content: ""; height: 16px; left: 0; position: absolute; top: 4px; transition: all 0.3s ease-in-out 0s; width: 16px; border-radius: 2px; }
.login-form .form-box .frm-input.checkbox label::after {background: transparent none repeat scroll 0 0;border: 1px solid rgba(42, 43, 66, 22%);content: "";height: 20px;left: 0;position: absolute;top: 4px;width: 20px;border-radius: 4px; background: rgba(0, 168, 107, 22%);}
.login-form .form-box .frm-input.checkbox input:checked + label::before {background: url("../images/tick.png") no-repeat right center; width: 10px; height: 8px; position: absolute;top: 10px;left: 5px;background-size: cover;} 
.login-form .form-box .frm-input.checkbox input:checked + label::after {border-color: #2B2C2E; } 
.login-form .form-box .frm-input.checkbox:last-child label { padding-bottom: 0;}
.login-form .form-box .frm-input.checkbox a {color: #2B2C2E;text-decoration:underline;transition:all .5s ease;font-family: 'Poppins-Regular';}
.login-form .form-box .frm-input.checkbox a:hover { color:#77DD77;}

.thank-you{width: 100%;float: left;padding: 50px 30px;}
.circles{ width: 183px; height: 183px; background: #77DD77; border: 1px solid #000000; border-radius: 100%; margin: 0 auto 35px; position: relative;}
.circles:after{position: absolute; content: "";background: url("../images/thank-img.svg") no-repeat 0 0; background-size: cover; width: 94px;height: 66px;top: 50%;transform: translateY(-50%);left: 0;right: 0;margin: 0 auto;}
.thank-you h3{width: 100%;float: left;color: #000; text-align: center; font-family: 'Poppins-Medium'; font-size: 22px; line-height: 36px; margin-bottom: 20px;}
.thank-you h6{width: 100%;float: left;color: #2F304C; text-align: center; font-family: 'Poppins-Regular'; font-size: 16px; line-height: 26px;  margin-bottom: 0;}
.circles-thank{ width: 136px; height: 136px;background: url("../images/thank-you-icon.svg") no-repeat 0 0; background-size: cover; border: 1px solid #000000; border-radius: 100%; margin: 0 auto 35px; position: relative;}

.success-message {width: 100%;float: left;text-align: left;}
.check-inbox{width: 100%;float: left;padding: 50px 150px;}
.inbox-img{width: 100%;float: left; text-align: center; margin-bottom: 30px;}
.inbox-img img{margin: 0px auto;}
.check-inbox h3{width: 100%;float: left; color: #000000; font-family: 'Poppins-Medium'; font-size: 32px; line-height: 36px; text-align: center; margin-bottom: 20px;}
.check-inbox p{width: 100%;float: left; color: #2F304C; font-family: 'Poppins-Regular'; font-size: 14px; line-height: 24px; text-align: center; margin-bottom: 20px;}
.check-inbox p span{font-family: 'Poppins-SemiBold';}
.check-inbox h6{width: 100%;float: left;font-family: 'Poppins-Regular'; font-size: 12px; line-height: 24px; color: #000; text-align: center; margin-bottom: 0;}
.check-inbox h6 a{color: #005EB8; font-family: 'Poppins-Regular'; font-size: 12px; line-height: 24px; transition:all .5s ease; text-decoration: underline; margin-bottom: 0;}
.check-inbox h6 a:hover{color:#77DD77;}


.unlock-email{width: 100%;float: left;padding: 50px 100px;}
.unlock-img{width: 100%;float: left; text-align: center; margin-bottom: 10px;}
.unlock-img img{margin: 0px auto; width: 120px;}
.unlock-email h3{width: 100%;float: left; color: #000000; font-family: 'Poppins-Regular'; font-size: 28px; line-height: 36px; text-align: center; margin-bottom: 20px;}
.unlock-email h3 span{font-family: 'Poppins-Bold'; display: block;}
.unlock-email p{width: 100%;float: left; color: #2F304C; font-family: 'Poppins-Regular'; font-size: 16px; line-height: 26px; text-align: center; margin-bottom: 20px;}
.unlock-btn{width: 100%;float: left; margin-top: 10px; padding: 0 65px;}
.unlock-btn ul{width: 100%;float: left; display: flex; flex-wrap: wrap; column-gap: 0; row-gap: 20px;}
.unlock-btn ul li{flex: 0 1 100%;}
.unlock-btn ul li .btn.sign-unlock{width: 100%;float: left; text-align: center; color: #000000; border: 1px solid #77DD77; background: #77DD77; border-radius: 12px; height: 48px; padding: 10px 25px; font-family: 'Poppins-SemiBold'; font-size: 16px; line-height: 26px; transition: all .5s ease;}
.unlock-btn ul li .btn.sign-unlock:hover{background: #ffff;}
.unlock-btn ul li .btn.login-unlock{width: 100%;float: left; text-align: center; color: #000000; border: 1px solid #000000; background: #fff; border-radius: 12px; height: 48px; padding: 10px 25px; font-family: 'Poppins-SemiBold'; font-size: 16px; line-height: 26px; transition: all .5s ease;}
.unlock-btn ul li .btn.login-unlock:hover{border: 1px solid #000; background: #000; color: #fff;}

.plot-card{width: 100%;float: left; padding: 20px 0 0 0;}
.plot-card h3{width: 100%;float: left; color: #000; font-size: 32px; line-height: 36px; font-family: 'Poppins-Regular'; text-align: center; margin-bottom: 30px;}
.plot-card h3 span{font-family: 'Poppins-SemiBold';}
.culture-list{width: 100%; float: left; margin-bottom: 40px;padding-right: 20px;}
.culture-list h4{width: 100%; float: left; color: #747474; font-family: 'Poppins-Regular'; font-size: 14px; line-height: 24px; letter-spacing: 2px; margin-bottom: 15px;}
.culture-list ul{width: 100%; float: left; display: flex; flex-wrap: nowrap;white-space: nowrap;overflow-x: auto;scrollbar-width: none;column-gap: 15px; row-gap: 15px;justify-content: left;}
.culture-list ul li { cursor: pointer; flex: 0 1 auto; border: 1px solid #A8A8A8; border-radius: 12px; font-size: 16px; line-height: 22px; font-family: 'Poppins-Regular'; display: flex; align-items: center; justify-content: center; padding: 5px 20px; height: 40px; transition: all .5s ease;
  -moz-transition: all .5s ease; -webkit-transition: all .5s ease; -o-transition: all .5s ease;}
.culture-list ul li:hover { border: 1px solid rgba(119, 221, 119, 44%); background: rgba(119, 221, 119, 44%);}
.culture-list ul li.active{background: rgba(119, 221, 119, 44%);font-family: 'Poppins-Medium'; border: 1px solid rgba(119, 221, 119, 44%); display: flex;align-content: center;column-gap: 10px;} 
.plot-list{ width: 100%; float: left;margin-bottom:40px; }
.plot-list ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 2%; row-gap: 25px;}
.plot-list ul li{flex: 0 1 32%; padding: 20px 15px; border-radius: 12px; border: 1px solid #DADADA; background: #FFFFFF; transition: all .5s ease;}
.plot-list ul li .plot-img{width: 100%; float: left; margin-bottom: 15px; display: flex; align-items: center; justify-content: space-between;}
.plot-list ul li h5{width: 100%; float: left; text-align: left; color: #000; font-family: 'Poppins-SemiBold'; font-size: 20px; line-height: 28px; margin-bottom: 10px; cursor: pointer; transition: all .5s ease;}
.plot-list ul li p{width: 100%; float: left;text-align: left; color: #595555; font-family: 'Poppins-Regular'; font-size: 14px; line-height: 22px; min-height: 100px; cursor: pointer;}
.plot-list ul li .charact-lst{width: 100%; float: left; background: #F4F5F7; border-radius: 8px; padding: 15px;}
.plot-list ul li .charact-lst h6{width: 100%;float: left;color: #747474;font-family: 'Poppins-Regular';font-size: 14px;line-height: 24px;letter-spacing: 2px;margin-bottom: 15px;}
.plot-list ul li .charact-lst ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 3%; row-gap: 12px;}
.plot-list ul li .charact-lst ul li{cursor: pointer; flex: 0 1 auto; border: 1px solid #eee; border-radius: 12px; font-size: 14px; line-height: 22px;color: #000000; font-family: 'Poppins-Regular'; display: flex; align-items: center; justify-content: center; padding: 4px 10px; box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 4%); transition: all .5s ease;}
.plot-list ul li:hover {border: 1px solid #DADADA; box-shadow: 0px 12px 20px 6px rgba(0, 0, 0, 10%);}
.plot-list ul li.active{border: 2.5px solid #00A86B; box-shadow: 0px 12px 20px 6px rgba(0, 0, 0, 10%);}
.plot-list ul li .charact-lst ul li:hover, .plot-list ul li .charact-lst ul li.active {border: 1px solid rgba(119, 221, 119, 44%); background: rgba(119, 221, 119, 44%); color: #000000;}
.plot-btn {width: 100%;float: left;text-align: center;display: flex;justify-content: center; margin-bottom: 40px;}
.plot-btn .btn.btn-primary.plot-btn {font-size: 16px;line-height: 24px;border-radius: 12px; font-family: 'Poppins-Regular';border: 1px solid #DADADA;background: #FFFFFF;height: 40px;color: #000;display: flex; padding: 10px 25px;margin: 0;width: auto;align-items: center;justify-content: center; transition: all .5s ease;}
.plot-btn .btn.btn-primary.plot-btn:hover{border: 1px solid #77DD77; background: #77DD77;}
.plot-list ul li:hover h5, .plot-list ul li.active h5{color: #00A86B;}


.stage {width: 100%;float: left;background: #F6F6F6; padding: 13px 20px; justify-content: center; margin-bottom: 30px;}
.circle-bg{justify-content: center; column-gap: 57px; display: flex; position: relative;}
.circle-bg:after{position: absolute;content: "";left: 0;top: 50%;transform: translateY(-50%);background-size: cover;width: 100%;height: 6px;border-bottom: 4px dotted #DEDEDE;z-index: 0;} 
.stage p{margin-bottom: 0; color: #171D25; font-size: 13px; line-height: 18px; font-family: 'Poppins-Regular';}
.stage .rounded-circle{border: 1px solid #D9D9D9; width: 25px; height: 25px; padding: 0 !important; position: relative; cursor: pointer;} 
.stage .text-center{display: flex; column-gap: 10px; align-items: center; position: relative;z-index: 9;background: #f6f6f6;}

.rounded-circle.time-process:after{position: absolute; content: ""; left: 0; top:50%; right: 0;margin: 0 auto;transform: translateY(-50%); width: 13px; height: 13px; background: #00A86B; border-radius: 100%;}
.rounded-circle.time-success{border: 1px solid #00A86B; background: #00A86B; position: relative;}
.time-success:after {position: absolute;content: ""; left: 0;top: 50%; right: 0;margin: 0 auto; background: url("../images/check-tick.svg") no-repeat 0 0; background-size: cover; width: 15px;height: 11px;transform: translateY(-50%);}

.btm-goback{width:100%; float: left; display: flex; flex-wrap: wrap; column-gap: 15px; justify-content: center;box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25); padding: 15px 20px 15px 245px; background: #fff;border-radius: 0 0 16px 16px; /*position: fixed;right: 36px;bottom: 0;*/ z-index: 99;}
.btm-goback .btn.btn-primary{border-radius: 12px; font-size: 16px; line-height: 24px; height: 48px; color: #000; padding: 10px 25px;  transition: all .5s ease;}
.btm-goback .btn.btn-primary.go-btn{ border: 1px solid #000000; background: #fff; font-family: 'Poppins-Regular'; }
.btm-goback .btn.btn-primary.go-btn:hover{background: #000; color: #fff;}
.btm-goback .btn.btn-primary.mail-btn{border: 1px solid #DADADA; background: #DADADA; font-family: 'Poppins-SemiBold';}
.btm-goback .btn.btn-primary.mail-btn.active-btn {background: #7d7;border: 1px solid #7d7;}
.btm-goback .btn.btn-primary.mail-btn:hover{background: #fff;}
.modal{--bs-modal-width: 725px !important;}
.btn-close{opacity: 1 !important;}
.btn-close:hover{opacity: 0.7 !important;}

/*body.open .btm-goback {width: calc(100% - 150px);}
body .btm-goback {width: calc(100% - 270px);}*/

/*loading*/
.loader-sec {width: 100%;float: left; padding: 65px 0;}
.loading {width: 100%; clear: both;display: flex;}
.loading img {width: 200px;margin: 0px auto 15px;}
.loading-grid { display: grid;grid-template-columns: repeat(3, 1fr);gap: 10px;margin: 0px auto 20px;width: 100px;}
.dot {width: 25px;height: 25px;background-color: lightgreen;border-radius: 50%;animation: fade 1.4s infinite;opacity: 0;}
.dot:nth-child(1) { animation-delay: 0s; opacity:1; }
.dot:nth-child(2) { animation-delay: 0.2s; opacity: 0.85; }
.dot:nth-child(3) { animation-delay: 0.4s; opacity: 0.7; }
.dot:nth-child(6) { animation-delay: 0.6s; opacity: 0.55; }
.dot:nth-child(9) { animation-delay: 0.8s; opacity: 0.4; }
.dot:nth-child(8) { animation-delay: 1s; opacity: 0.25; }
.dot:nth-child(7) { animation-delay: 1.2s; opacity: 0.1; }
.dot:nth-child(4) { animation-delay: 1.4s; opacity: 0; }
.dot:nth-child(5) { background-color:#fff; opacity: 0; }
@keyframes fade {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
.loading-cnt{width: 100%; float: left; text-align: center;}
.loading-cnt p{width: 100%; float: left;font-family: 'Poppins-Regular'; color: #000; font-size: 24px; line-height: 35px; margin-bottom: 0;}
.loading-cnt p span{display: inline-flex; font-family: 'Poppins-Medium'; column-gap: 15px;}
.loading-cnt p b{display: block; font-family: 'Poppins-Medium'; font-weight: normal; display: flex; justify-content: center; column-gap: 10px;align-items: center;}
.loading-cnt p span{background: url("../images/loading-arw.svg") no-repeat 0 0; width: 43px; height: 21px; background-size: cover;}

.plot-card-content-area .col-lg-12 {padding: 0;}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari */
}

.custom-scrollbar {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Edge */
}


.custom-scrollbar {width: 100%; float: left; display: flex !important;column-gap: 20px !important;row-gap: 0 !important;}
.custom-scrollbar-inner {flex: 0 1 auto;background: #F6F6F6; border: 1px solid #A8A8A8; color: #000000;font-family: 'Poppins-Regular'; font-size: 16px; line-height: 22px; padding: 8px 15px; display: inline-flex; white-space: nowrap; transition: all .5s ease; cursor: pointer;}
.custom-scrollbar-inner.active, .custom-scrollbar-inner:hover{border: 1px solid rgba(119, 221, 119, 44%);background: rgba(119, 221, 119, 44%);}
#head-rgt-lft-id {
	display: none;
	position: absolute;
	top: -12px;
	left: -19px;
	width: 225px;
	background: #f4f5f7;
	height:80px;border-radius: 16px 0 0 0;
}

.olympian-title{width: 100%; float: left; display: flex; flex-wrap: wrap; align-items: center; column-gap: 12px; row-gap: 0; margin-bottom: 25px;}
.olympian-logo{ width: 46px; height: 46px;}
.olympian-logo img{width: 100%;}
.olympian-cnt{width: calc(100% - 58px); float: left; padding-right: 10px;}
.olympian-cnt p{width: 100%; float: left; font-size: 16px; line-height: 23px; letter-spacing: -2%; color: #000; font-family: 'Poppins-Medium'; margin-bottom: 0;}
.btn.btn-primary.thankyou-btn{border: 1px solid #D6D6D6;border-radius: 8px;font-size: 16px;line-height: 22px;font-family: 'Poppins-Regular';display: flex;align-items: center;justify-content: center;padding: 5px 20px;transition: all .5s ease; background: rgba(119, 221, 119, 44%); color: #000; height: 48px; column-gap: 10px;}

.get-touch {width: 640px;margin: 10px auto;text-align: center;}
.get-touch h2{width: 100%; float: left; font-family: 'Poppins-Bold'; text-align: center; color: #000; font-size: 32px; line-height: 36px; margin-bottom: 20px; display: flex; column-gap: 10px;justify-content: center; align-items: center;}
.get-touch p{width: 100%; float: left; font-family: 'Poppins-Regular';text-align: center; color: #706D6D; font-size: 18px; line-height: 25px; margin-bottom: 40px;}

.get-touch-inner {width: 540px;margin: 0 auto;display: flex;flex-wrap: wrap;}
.get-touch-inner ul{width: 100%; float: left; display: flex; flex-wrap: wrap; column-gap: 0; row-gap: 25px;}
.get-touch-inner ul li{flex: 0 1 100%;}
.get-touch-inner ul li .form-control{width: 100%; float: left; height: 48px; border: 1px solid #63666A; border-radius: 12px; color: #5E6368; font-size: 16px; line-height: 25px; font-family: 'Poppins-Regular'; transition: all .5s ease; cursor: pointer; padding: 10px 15px;}
.get-touch-inner ul li .invalid-feedback{float: left; text-align: left;}
.get-touch-inner ul li .submit.btn.btn-primary{width: 100%; background: #77DD77; border-radius: 12px; height: 48px; color: #000000; font-family: 'Poppins-SemiBold'; font-size: 16px; line-height: 24px; border: 1px solid #77DD77; transition: all .5s ease; cursor: pointer;}
.get-touch-inner ul li textarea {height: 150px !important;}
.get-touch-inner ul li .form-control.is-invalid:focus, .get-touch-inner ul li .was-validated .form-control:invalid:focus {border-color: inherit;box-shadow: none; border: 1px solid #de4352;}
.get-touch-inner ul li .form-control:focus{box-shadow: inherit !important;}
.get-touch-inner ul li .submit.btn.btn-primary:hover {background: #59CBE8;color: #fff; border: 1px solid #59CBE8;}
.get-touch-inner ul li .form-control.is-invalid{background-image: none !important; border: 1px solid #de4352;}


/*.get-touch-inner ul li .frm-input.checkbox { float: left; width: 100%; margin-bottom: 0; display: flex; align-items: center;} 
.get-touch-inner ul li .frm-input.checkbox input {  height: 1px; left: 0; position: absolute; top: 0; visibility: hidden; width: 1px;}
.get-touch-inner ul li .frm-input.checkbox label {color: #2B2C2E;cursor: pointer;font-size: 14px;padding-left: 32px;position: relative;float: left;width: auto;line-height:32px;text-align:left;font-family: 'Poppins-Regular';}
.get-touch-inner ul li .frm-input.checkbox label::before { background: #fff; content: ""; height: 16px; left: 0; position: absolute; top: 4px; transition: all 0.3s ease-in-out 0s; width: 16px; border-radius: 2px; }
.get-touch-inner ul li .frm-input.checkbox label::after {background: transparent none repeat scroll 0 0;border: 1px solid #000;content: "";height: 25px;left: 0;position: absolute;top: 4px;width: 25px;border-radius: 100%; background: #77DD77;}
.get-touch-inner ul li .frm-input.checkbox input:checked + label::before {background: url("../images/c-tick.svg") no-repeat right center; width: 12px; height: 8px; position: absolute;top: 13px;left: 7px;background-size: cover; z-index: 99;} 
.get-touch-inner ul li .frm-input.checkbox input:checked + label::after {border-color: #2B2C2E; } 
.get-touch-inner ul li .frm-input.checkbox:last-child label { padding-bottom: 0;}
.get-touch-inner ul li .frm-input.checkbox a {color: #2B2C2E;text-decoration:underline;transition:all .5s ease;font-family: 'Poppins-Regular';}
.get-touch-inner ul li .frm-input.checkbox a:hover { color:#77DD77;}*/

.get-touch .thank-msg {float: left; width: 100%; background:#F6F6F6; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; text-align: center; column-gap: 10px; margin-top: 25px;}
.get-touch .thank-msg p{width: auto; color: #2B2C2E;font-size: 14px;line-height:25px;text-align:left;font-family: 'Poppins-Regular'; margin-bottom: 0;}


/* .no-sidebar #main-area{padding-left: 0px; padding-right: 0;}
.no-sidebar .header{width: 100%;}   */

.no-sidebar .menu{position: fixed;left: 0;bottom: 50px; justify-content: inherit;width: 42px;  right: auto;}
.no-sidebar .sidebar {width: auto; padding-right: 0;}
.no-sidebar .logo{display: none;}
.no-sidebar .m-icons {display: none;}
.no-sidebar #main-area {padding-left: 0; position: relative; padding-right: 0;}
.no-sidebar .header{width: 100%;}
.no-sidebar .menu span { background: url("../images/close-side-bar.svg") no-repeat center center;}
.no-sidebar.open .menu span {background: url("../images/open-sidebar-bar.svg") no-repeat center center;}
.no-sidebar.open .menu{padding: 0 0 0 43px;}
.mythology-sec .myth-rgt.no-pad { padding-left: 0px;}
.mythology-sec .myth-rgt.no-keyword { width: 100%; }
.mythology-sec .myth-rgt .myth-rgt-no-pad-inner { padding: 0 20px;width: 100%;float: left;min-height: 542px;}
.plot-card-content-area .award-inner.p-0 {width: 100%;}


.alert.alert-info.text-center {background: rgba(119, 221, 119, 44%);border: 1px solid rgba(119, 221, 119, 44%);color: #000;}
.btn.btn-secondary {background: #7d7;border: 1px solid #7d7;color: #000; font-family: 'Poppins-Regular'; min-width: 100px;transition: all .5s ease;}
.btn.btn-secondary:hover{background: #fff;color: #000;border: 1px solid #7d7;}
.confirm-popup{width: 100%; float: left; padding: 35px;}
.confirm-popup .modal-title.h4{width: 100%;float: left;color: #000;font-family: 'Poppins-Medium';font-size: 32px;line-height: 36px;text-align: left;margin-bottom: 15px;}
.confirm-popup .modal-body{padding: 0 !important; margin-bottom: 20px;}
.confirm-popup .modal-body .alert.alert-info{background: rgba(119, 221, 119, 44%);border: 1px solid rgba(119, 221, 119, 44%);color: #000; font-family: 'Poppins-Regular'; margin-bottom: 0;}

.confirm-popup .modal-footer .btn.btn-secondary{font-family: 'Poppins-SemiBold'; border-radius: 12px;font-size: 16px;line-height: 24px;height: 48px;color: #000;padding: 10px 25px; background: #7d7;border: 1px solid #7d7; min-width: 130px;transition: all .5s ease;}
.confirm-popup .modal-footer .btn.btn-primary{font-family: 'Poppins-SemiBold';border-radius: 12px;font-size: 16px;line-height: 24px;height: 48px;color: #000;padding: 10px 25px; background: #7d7;border: 1px solid #7d7; min-width: 130px;transition: all .5s ease;}
.confirm-popup .modal-footer .btn.btn-secondary:hover, .confirm-popup .modal-footer .btn.btn-primary:hover{background: #fff;}
.modal-dialog {top: 5%;}

.alert.alert-danger{float: left; width: 100%; text-align: center; font-size: 16px; font-family: 'Poppins-Medium'; margin-top: 30px;}
.alert-success { clear: both; float: left; width: 100%; text-align: center; font-size: 16px; font-family: 'Poppins-Medium'; margin-top: 30px; }

.plot-card-content-area.is-sticky .plot-card h3 {position: fixed;left: 505px;top: 0;z-index: 99;background: red;padding: 15px;width: calc(100% - 516px);}
.plot-card-content-area.is-sticky {position: relative;z-index: 9999;}
body.open .plot-card-content-area.is-sticky h3 {left: 370px;width: calc(100% - 382px);}

.regenerate-sec {width: 100%;float: left; padding: 65px 0;}
.regenerate-img {width: 100%; clear: both;display: flex;}
.regenerate-img img {width: 200px;margin: 0px auto 15px;}
.regenerate-cnt{width: 100%; float: left; text-align: center;}
.regenerate-cnt p{width: 100%; float: left;font-family: 'Poppins-Regular'; color: #000; font-size: 24px; line-height: 35px; margin-bottom: 0; display: flex; column-gap: 10px; text-align:left;  justify-content: center; align-items: center;}
.regenerate-cnt p span{display: inline-flex; line-height: 18px;}
.award-inner-pad.step4 {column-gap: 30px;}
.step4-lft {width:calc(100% - 60px);}
.step4-rgt {width: 120px;position: fixed;top: 55%;right: 25px;transform: translateY(-50%);}
.edit-sec.step4 {width: 95%;}
.show-rgt-btn {display: flex;flex-wrap: wrap;row-gap: 12px; column-gap: 0;border: none; border-radius: 0;padding: 0;}
.show-rgt-btn .btn.btn-primary.share-btn {border: 1px solid #A8A8A8;border-radius: 8px;padding: 5px 12px;flex: 0 1 100%;background: #fff;}


.show-rgt-btn .btn.btn-primary.share-btn.active{background: rgba(119, 221, 119, 20%);}
.show-rgt-btn .btn.btn-primary.share-btn strong{display: none;}
.show-rgt-btn .btn.btn-primary.share-btn.active strong{font-weight: normal; font-size: 13px;line-height: 22px;color: #000; column-gap: 7px;display: flex;align-items: center;font-family: 'Poppins-Regular'; font-weight: normal;}
.show-rgt-btn .btn.btn-primary.share-btn.active span{display: none;}

.footer-btm {padding: 0 0 25px 0; width: 100%; text-align: center; position: absolute; bottom: 0; z-index: 9;}
.footer-btm p{ font-size: 12px; line-height: 18px; color: #46494D; font-family: 'Poppins-Regular'; text-align: center; margin-bottom: 0;}
.footer-btm p a{color: #46494D; font-size: 12px; line-height: 18px; font-family: 'Poppins-Regular'; text-decoration: underline; transition: all .5s ease;}
.footer-btm p a:hover{color: #00a86b;}
.footer-btm.mail-page {padding: 0 0 15px 0;}

.policy-btm{width: 100%; display: inline-flex; justify-content: center; text-align: center; margin-top: 15px;}
.policy-btm a{color: #46494D; font-size: 12px; line-height: 18px; font-family: 'Poppins-Regular'; text-decoration: underline; transition: all .5s ease;}
.policy-btm a:hover{color: #00a86b;}
.modal-dialog .policy-btm a { text-decoration: none;}
.modal-dialog .policy-btm a:hover { text-decoration: underline;}

.main-content {position: relative;}
.invalid-feedback { font-size: 14px !important; }
