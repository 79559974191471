ul, ol {
  list-style-type: none;
  padding: 0 !important;
  margin: 0 !important;
}

ul, li {
  list-style: none;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);

  background: #fff;
  border-bottom: 1px solid #EEF1F9;
  border-radius: 5px 5px 0 0;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav li, .nav li a {
  position: relative;
  display: block;
}
.nav-tabs li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs li .active, .nav-tabs li.active {
  font-weight: bold;
  border: none;
  color: #20446f !important;
  background: transparent !important;
  border-bottom: 2px solid #20446f !important;
  border-radius: 0;
  cursor: default;
}
.nav-tabs .nav-link {
  border: none;
  margin-bottom: 0 !important;
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width)* -1);
  background: 0 0;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav li, .nav li a {
  position: relative;
  display: block;
}
.nav-tabs li a {
  padding: 20px;
  margin-right: 0;
  border-radius: 0;
  color: inherit;
  line-height: 1.42857143;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs li a:not(.active):hover {
  background: transparent;
  border-color: transparent !important;
  color: #20446f;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}