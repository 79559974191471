body {
  margin: 0;
  font-family: 'Poppins-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins-Regular';
}


.login {
  background-color: #0667b8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 15px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login:before {
  background-image: url(../images/auth-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}


.log-lft h1 {
  font-family: 'Poppins-Regular';
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0px;
  text-align: left;
}

.log-lft p {
  font-family: 'Poppins-Regular';
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.b-note p {
  font-family: 'Poppins-Regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.log-rgt .l-logo {
   
  margin: 0 auto;
  text-align: center;
}

.log-rgt h1 {
  font-family: 'Poppins-Regular';
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
}

.log-rgt p {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.log-rgt label {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
}

.form-control {
  font-family: 'Poppins-Regular';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 11px 15px;
}

.input-group ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #787878;
  font-weight: 400;
  opacity: 1;
}

.input-group ::-moz-placeholder {
  /* Firefox 19+ */
  color: #787878;
  font-weight: 400;
  opacity: 1;
}

.input-group :-ms-input-placeholder {
  /* IE 10+ */
  color: #787878;
  font-weight: 400;
  opacity: 1;
}

.input-group :-moz-placeholder {
  /* Firefox 18- */
  color: #787878;
  font-weight: 400;
  opacity: 1;
}

.log-rgt .form-check label {
  /* font-family: Inter; */
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
}

.btn-primary {
  color: #fff;
  background-color: #007AFF;
  border-color: #007AFF;
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.my-acc {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 360px;
  padding: 15px 20px;
  position: relative;
}
.log-lft img {
  float: left;
  width: 100%;
}
a{text-decoration: none;}
.input-group>.form-control:not(:focus).is-invalid{    border-top-right-radius: 5px!important;
  border-bottom-right-radius: 5px!important;}


  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);    
}

.text-left { text-align: left; }