@charset "utf-8";


/* #Media Queries
================================================== */
@media only screen and (min-width: 1920px){
.welcome-cnt h2{font-size: 56px;line-height: 56px; margin-bottom: 20px;}
.characteristic-tag{font-size: 13px;}
.plot-cards ul li .plot-list{padding: 10px;}
.plot-cards ul li h3{padding: 0 20px;}
.plot-cards ul li {flex: 0 1 49%;}
.btn.btn-primary.idea-btn, .btn.btn-primary.go-back{padding: 0px 15px;}

}
@media only screen and (max-width: 1280px) {

.welcome-cnt h2{font-size: 56px;line-height: 56px; margin-bottom: 20px;}
.plot-cards ul li {flex: 0 1 49%;}
.recognition-content {width: 100%;padding-right: 0;}
.characteristics{width: 100%;}
.recognition-card{row-gap: 15px;}
.plot-cards ul li h3{padding: 0;}
.main-title{font-size: 16px; line-height: 26px;}

}

@media screen and (min-width:1280px) and (max-width:1400px) {

.welcome-cnt h2{font-size: 56px;line-height: 56px; margin-bottom: 20px;}
.plot-cards ul li {flex: 0 1 49%;}
.recognition-content {width: 100%;padding-right: 0;}
.characteristics{width: 100%;}
.recognition-card{row-gap: 15px;}
.plot-cards ul li h3{padding: 0;}
.main-title{font-size: 16px; line-height: 26px;}

}

@media screen and (min-width:1024px) and (max-width:1279px) {
img {max-width:100%;}

.award-inner{padding: 0; width: 90%;}
.p-15 {padding: 15px 0;}
.edit-sec span {width: 36px;height: 36px;}
.award-cnt ul{column-gap: 12px; row-gap: 12px;}
.award-cnt ul li{font-size: 15px;}
.award-rgt {width: calc(100% - 63px);}
.award-title h5{font-size: 15px;}
.btn.btn-primary.ideate-btn{font-size: 15px; padding: 8px 20px;}
/*.main-title span {width: 48px;height: 48px;font-size: 32px;line-height: 32px;}*/
.head-rgt ul li .login-btn {font-size: 15px;line-height: 24px;padding: 0px 20px;height: 42px;min-width: 100px;}
.head-rgt ul li .sign-btn{font-size: 15px;line-height: 24px;padding: 0px 20px;height: 42px;min-width: 100px;}
.btn.btn-primary.idea-btn, .btn.btn-primary.go-back{font-size: 14px; padding: 0px 10px; height: 42px;}
.btn.btn-primary.copy{height: 42px; font-size: 14px;padding: 0px 15px;}
.notification{width: 42px;height: 42px;}
.main-menu ul li .n-cnt a{font-size: 15px;}

.welcome-cnt{padding: 40px 0; width: 100%;}
.welcome-cnt h2{font-size: 42px;line-height: 46px;margin-bottom: 20px;}
.welcome-cnt h2 img{width: 265px;}
.ideate-inner{width: 100%;}
.subject-cnt {padding-right: 20px;}
.subject-cnt p{font-size: 14px;line-height: 24px;}
.subject-cnt h5{font-size: 15px;}
.step4-lft {width: calc(100% - 105px);}
.plot-cards ul li .plot-list {padding: 10px;}
.recognition-content {width: 100%;padding-right: 0;}
.characteristics {width: 100%;}
.myth-list{padding: 10px;}
.recognition-card{row-gap: 15px; padding: 10px;}
.plot-cards ul li {flex: 0 1 49%;}
.plot-cards ul li h3{font-size: 16px; padding: 0; margin-bottom: 15px;}
.myth-list ul li [type="radio"]:checked + .recognition-card label::before, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::before {left: -40px;}
.myth-list ul li [type="radio"]:checked + .recognition-card label::after, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::after {left: -36px;}
.characteristic-tag {font-size: 13px;}
.myth-subtitle h2{font-size: 16px;}
.myth-submenu ul li a{font-size: 13px;}

/*popup form*/
.modal{--bs-modal-width: 580px !important;}
.login-form{padding: 50px 20px;}
.login-form h2 {font-size: 28px;line-height: 32px;}
.login-form .form-box input[type="email"]{height: 48px; padding: 10px 15px 10px;}
.verification-box ul li input[type="text"]{font-size: 20px;}
.btn-close {top: 15px;right: 15px;}
.verification-box p{line-height: 20px;}
.verification-box{margin-bottom: 20px;}
.btn.login-btn{padding: 10px;}
.or-txt {margin: 15px 0;}
.btn.gle-btn{padding: 10px 10px; font-size: 14px;line-height: 25px;margin-bottom: 20px;}

.thank-you{padding: 50px 50px;}
.circles {width: 136px;height: 136px; margin: 0 auto 20px;}
.circles::after {width: 70px;height: 50px;}
.thank-you h3{font-size: 28px;line-height: 32px;}

.check-inbox {padding: 40px 50px;}
.inbox-img{margin-bottom: 22px;}
.inbox-img img {width: 165px;}
.check-inbox h3{font-size: 28px;line-height: 32px;}
.check-inbox p{font-size: 14px; line-height: 22px; margin-bottom: 15px;}

.unlock-email{padding: 50px 50px;}
.unlock-email img{width: 165px;}
.unlock-img{margin-bottom: 25px;}
.unlock-email h3{font-size: 28px;line-height: 32px;}
.unlock-email p{font-size: 14px;line-height: 24px; margin-bottom: 15px;}
.unlock-btn{margin-top: 0;}
.unlock-btn{padding: 0 20px;}
.unlock-btn ul{row-gap: 15px;}
.unlock-btn ul li .btn.sign-unlock, .unlock-btn ul li .btn.login-unlock{font-size: 14px;}
.main-title span {display: inline;}
.plot-card h3{font-size: 28px;line-height: 32px; margin-bottom: 20px;}
.prompt-content-edit-sec{margin-bottom: 25px;}
.plot-card{padding: 30px 0;}
.culture-list{margin-bottom: 30px;}
.plot-list ul{row-gap: 20px;}
.plot-list ul li {flex: 0 1 49%;}
.plot-list ul li h5{min-height: 60px;}
.plot-list ul li .charact-lst ul li{font-size: 13px;}
.custom-scrollbar-inner{font-size: 15px;}
.btm-goback .btn.btn-primary{font-size: 14px;}
.loading-cnt p{font-size: 22px;}
.tone-lst ul{column-gap: 10px;row-gap: 10px;}
.tone-lst ul li {font-size: 14px; padding: 8px 15px;}

}

@media only screen and (max-width: 1023px) {
#head-rgt .main-menu ul { width: auto;}
.main-menu ul li .n-cnt { width: 70px; }

}

/* Smaller than standard 1024 (devices and browsers) */
@media screen and (min-width:768px) and (max-width:1023px) {
img {max-width:100%;}

body {padding: 0 !important; background: #fbfbfb !important;}
#layout-wrapper{border-radius: 0; margin-left: 70px;}
.open #layout-wrapper{margin-left: 70px;}
.logo{width: auto; left: 0; padding: 13px 5px;}
.logo .mob{display: block;}
.logo .desktop{display: none;}
.main-bar ul li span{display: none;}
.sidebar{width: 68px; overflow: hidden; padding-right: 0;}
.open .sidebar {width: 68px; overflow: hidden;}
.main-bar ul li{padding: 3px 10px;}
.menu{padding: 0 0 0 10px;}
.main-bar ul li a{column-gap: 0; width: 48px; height: 48px; padding: 13px 15px;}
.open .main-bar ul li a{width: 48px; height: 48px;}
#main-area {padding-left: 0; padding-right: 0;}
.welcome-cnt{padding: 30px 0; width: 100%;}
.p-15 {padding: 0;}
.welcome-cnt h2{font-size: 36px;line-height: 42px; margin-bottom: 15px;}
.welcome-cnt h2 img{width: 220px;}
.welcome-cnt p{font-size: 16px;line-height: 24px;margin-bottom: 20px;}
.p-10 {padding: 0;}
.header{width: calc(100% - 68px); padding: 10px 10px 10px; justify-content: right; background: #fafafb;}
.open .header {width: calc(100% - 68px);}
.head-rgt{justify-content: right; column-gap: 12px;}
.head-rgt .btn.btn-primary.idea-btn strong{display: none;}
#generate-new-idea-id .btn.btn-primary.idea-btn{min-width: 48px;height: 40px;padding: 0px 15px;  border-radius: 12px; }
#generate-new-idea-id .btn.btn-primary.idea-btn:hover{background: #77DD77; border: 1px solid #77DD77;}
.head-rgt ul li .sign-btn{min-width: 48px;height: 40px; padding: 0px 15px;}
.head-rgt ul li .login-btn{height: 40px;min-width: 40px;padding: 0px 19px;}
.head-rgt ul li .sign-btn strong, .head-rgt ul li .login-btn strong{display: none;}
.btn.btn-primary.sign-btn:hover .svg-inline--fa.fa-user-plus, .head-rgt ul li .login-btn:hover .svg-inline--fa.fa-user {color: #fff !important;}
.head-rgt ul li .sign-btn span, .head-rgt ul li .login-btn span{display: block;}
.notification:hover .box{display: none;}
.main-lft{background: transparent; width: calc(100% + 15px);}
/*.award-inner-pad{padding: 15px 15px;}*/
.open #main-area {padding-left: 0;}
.award-cnt{padding: 15px 15px;}
.award-cnt ul{column-gap: 10px; row-gap: 10px;}
.award-cnt ul li{flex: 0 1 auto; padding: 6px 15px; font-size: 14px; justify-content: left;}
.edit-sec{font-size: 14px;line-height: 20px; padding: 0 40px 0 15px; height: 62px; margin-bottom: 15px;}
.edit-sec span {width: 34px;height: 34px; right: 15px;}
.award-title h5{font-size: 15px; line-height: 22px;}
/*.main-title{font-size: 16px;line-height: 22px; margin-bottom: 25px;}
.main-title span {width: 42px;height: 42px; font-size: 22px;line-height: 22px;}*/
.award-rgt {width: calc(100% - 57px);}
.btn.btn-primary.ideate-btn{height: 42px; font-size: 14px;line-height: 22px;}

.subject-sec{row-gap: 20px;}
.tone-lst{flex-wrap: wrap;column-gap: 0;row-gap: 10px;}
.tone-lst h3 {width: 100%;}
.tone-lst ul{width: 100%; row-gap: 10px; column-gap: 3%;}
.tone-lst ul li{flex: 0 1 48.5%;}
.subject-cnt{padding-right: 0;}
.subject-cnt h6{font-size: 14px;line-height: 24px;margin-bottom: 15px;}
.subject-cnt h5{font-size: 14px;line-height: 24px;}
.subject-cnt p{font-size: 14px;line-height: 24px;}
.show-btn.full-width{flex-wrap: wrap; row-gap: 15px;}
.btn.btn-primary.share-btn{min-width: 44px;height: 42px;}
.btn.btn-primary.copy{height: 42px;min-width: 100px; padding: 0px 20px; font-size: 14px;}
.btn.btn-primary.idea-btn, .btn.btn-primary.go-back{min-width: 100px;height: 42px; font-size: 14px; padding: 0px 15px;}
.edit-sec input {font-size: 14px; padding-right: 25px;}


.plot-cards ul li .plot-list {padding: 10px;}
.recognition-content {width: 100%;padding-right: 0;}
.characteristics {width: 100%;}
.myth-list{padding: 10px;}
.recognition-card{row-gap: 15px; padding: 10px;}
.plot-cards ul li {flex: 0 1 49%;}
.plot-cards ul li h3{font-size: 16px; padding: 0; margin-bottom: 15px;}

.myth-list ul li [type="radio"]:checked + .recognition-card label::before, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::before {left: -40px;}
.myth-list ul li [type="radio"]:checked + .recognition-card label::after, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::after {left: -36px;}
.characteristic-tag {font-size: 13px;}
.myth-subtitle h2{font-size: 16px;}
.myth-submenu ul li a{font-size: 13px;}

.ideate-box{row-gap: 20px; width: 94%;}

/*popup form*/
.modal{--bs-modal-width: 420px !important;}
.login-form{padding: 40px 20px;}
.login-pad{padding: 0;}
.verification-box p{text-align: center;}
.login-form h2 {font-size: 20px;line-height: 28px; margin-bottom: 20px;}
.login-form .form-box input[type="email"]{height: 48px; padding: 10px 15px 10px;}
.verification-box ul li input[type="text"]{font-size: 20px;}
.btn-close {top: 15px;right: 15px;}
.login-form .floating-label{top: 14px; left: 15px;}
.popup-logo{margin-bottom: 20px;}
.verification-box p{line-height: 20px;}
.verification-box{margin-bottom: 20px;}
.btn.login-btn{padding: 10px;}
.or-txt {margin: 15px 0;}
.btn.gle-btn{padding: 10px 10px; font-size: 14px;line-height: 25px;margin-bottom: 20px;}

.thank-you{padding: 40px 20px;}
.circles {width: 110px;height: 110px; margin: 0 auto 20px;}
.circles::after {width: 70px;height: 50px;}
.thank-you h3{font-size: 22px;line-height: 28px;}

.check-inbox {padding: 40px 20px;}
.inbox-img{margin-bottom: 22px;}
.inbox-img img {width: 165px;}
.check-inbox h3{font-size: 22px;line-height: 28px;margin-bottom: 15px;}
.check-inbox p{font-size: 14px; line-height: 22px; margin-bottom: 15px;}

.unlock-email{padding: 40px 20px;}
.unlock-email img{width: 165px;}
.unlock-img{margin-bottom: 25px;}
.unlock-email h3{font-size: 22px;line-height: 28px; margin-bottom: 15px;}
.unlock-email p{font-size: 14px;line-height: 24px; margin-bottom: 15px;}
.unlock-btn{margin-top: 0;}
.unlock-btn{padding: 0 20px;}
.unlock-btn ul{row-gap: 15px;}
.unlock-btn ul li .btn.sign-unlock, .unlock-btn ul li .btn.login-unlock{font-size: 14px;}

.plot-card{padding: 30px 0;}
.plot-card h3{font-size: 22px; margin-bottom: 20px;}
.plot-list ul{row-gap: 20px; justify-content: center;}
.plot-list ul li {flex: 0 1 100%;}
.plot-list ul li p{min-height: inherit;}
.plot-list ul li ul{justify-content: left;}
.culture-list ul li{font-size: 15px;}
.btm-goback .btn.btn-primary{font-size: 14px; height: 42px; padding: 5px 20px;}
.plot-list ul li h5{font-size: 18px;}

.get-touch {margin: 40px auto;text-align: center;padding: 15px;}
.get-touch-inner {width: 100%;}
.get-touch h2{font-size: 28px; margin-bottom: 12px;}
.get-touch h2 span img{width: 36px;}
.get-touch p{font-size: 15px; margin-bottom: 20px;}
.get-touch p br{display: none;}
.get-touch-inner ul{row-gap: 15px;}
.get-touch-inner ul li .frm-input.checkbox label::after {width: 18px; height: 18px;}
.get-touch-inner ul li .frm-input.checkbox label{font-size: 13px; line-height: 22px; padding-left: 28px;}
.get-touch-inner ul li .form-control{font-size: 14px;}
.get-touch-inner ul li .frm-input.checkbox input:checked + label::before {width: 10px;height: 7px;top: 10px;left: 4px;}

.no-sidebar .header {width: 100%;display: flex;align-items: center;}
.award-list{width: 100%;}
.prompt-content-edit-sec{margin-bottom: 20px;}
.main-lft-pad {padding: 0;}
.main-title span {display: initial;}
.prompt-content-edit-sec .content {font-size: 14px;line-height: 20px; padding: 15px;}
.show-btn .skip-btn{font-size: 14px; justify-content: center; height: 42px;}
#head-rgt-lft-id{display: none !important;}
.award-inner {width: 90%;}
.culture-list{padding-right: 0; margin-bottom: 30px;}
.custom-scrollbar{column-gap: 10px !important;}
.custom-scrollbar-inner{font-size: 14px; padding: 8px 15px;}
.stage {display: flex;flex-wrap: nowrap;white-space: nowrap;overflow-x: auto;scrollbar-width: none;justify-content: center;}

.loader-sec {padding: 30px 0;}
.loading {margin: 0px auto 10px;}
.loading img{width: 100px;}
.dot {width: 14px;height: 14px;}
.show-btn {padding: 10px 0;}
.rate-mail ul{row-gap: 5px; flex-wrap: wrap;}
.show-rgt.full-width{justify-content: space-between; row-gap: 20px;}
.show-rgt-btn .btn.btn-primary.share-btn {min-width: auto;height: auto;}
.loading-cnt p {font-size: 20px;line-height: 32px;}
.loading-cnt p br {display: none;}
.loading-cnt p span {display: inline;}
.mythology-sec .myth-rgt .myth-rgt-no-pad-inner {padding: 0 15px;}
.ideate-inner .btn.btn-primary.ideate-btn {right: 0;}

.award-inner-pad.step4 {column-gap: 0;}
.step4-lft {width: 100%;}
.step4-rgt {width: 100%;position: inherit;top: auto;right: auto;transform: none;}
.edit-sec.step4 {width: 100%;margin: 0 auto 20px 0;}
.show-btn.full-width{width: 100%;}
.show-rgt-btn{column-gap: 10px;}
.show-rgt-btn .btn.btn-primary.share-btn{flex: 0 1 auto;}
.edit-inner{flex-basis: calc(100% - 15px);}
.ideate-list {width: 100%;padding: 0 15px;}

}

/* Smaller than standard 768 (devices and browsers) */
@media screen and (max-width:767px) {
img {max-width:100%; }

body {background: #fbfbfb !important;padding: 0px !important;}
.logo{width: auto; left: 0; padding: 13px 7px;}
/*.open .logo{width: 172px; left: 0;}*/
.logo .mob{display: block;}
.logo .desktop{display: none;}
.logo::after {width: 80%;}
.open .logo::after {width: 80%;left: 0;}
.main-menu ul li .n-cnt{width: 70px;}
.main-bar ul li span{display: none;}
.sidebar{width: 68px; overflow: hidden; padding-right: 0;}
.open .sidebar {width: 68px; overflow: hidden;}
.main-bar ul li{padding: 3px 10px;}
.menu{padding: 0 0 0 10px; display: none !important;}
.main-bar ul li a{border-radius: 50%; column-gap: 0; width: 48px !important; height: 48px; padding: 13px 15px;}
#main-area {padding-left: 0; padding-right: 0;}
.welcome-cnt{padding: 30px 0; width: 100%;}
.p-15 {padding: 0;}
.welcome-cnt .welcome-logo{margin-bottom: 15px;}
.welcome-cnt h2 {font-size: 32px;line-height: 36px;}
.welcome-cnt p br{display: none;}
.welcome-cnt p{font-size: 16px;line-height: 24px;margin-bottom: 25px;}
.p-10 {padding: 0;}
.header{width: 100%; padding: 10px 10px 10px; justify-content: right; background: #fbfbfb;}
.open .header {width: 100%; background: #fbfbfb;}
.head-rgt{justify-content: right; column-gap:12px;}
.head-rgt .btn.btn-primary.idea-btn strong{display: none;}
#generate-new-idea-id .btn.btn-primary.idea-btn{min-width: 48px;height: 40px;padding: 0px 15px;  border-radius: 12px;}
#generate-new-idea-id .btn.btn-primary.idea-btn:hover{background: #77DD77; border: 1px solid #77DD77;}
.head-rgt ul li .sign-btn{min-width: 48px;height: 40px; padding: 0px 15px;}
.head-rgt ul li .login-btn{height: 40px;min-width: 40px;padding: 0px 19px;}
.head-rgt ul li .sign-btn strong, .head-rgt ul li .login-btn strong{display: none;}
.btn.btn-primary.sign-btn:hover .svg-inline--fa.fa-user-plus, .head-rgt ul li .login-btn:hover .svg-inline--fa.fa-user {color: #fff !important;}
.head-rgt ul li .sign-btn span, .head-rgt ul li .login-btn span{display: block;}
.main-menu ul{padding: 5px !important; }
.main-menu ul > li {width: auto;}
.main-menu ul li .n-cnt a{font-size: 14px;}
.notification:hover .box {display: none;}
.award-inner{padding: 15px 15px; width: 100%;}
.award-inner.employe {width: 100%;padding: 0;}
.main-lft{background: transparent; /*width: calc(100% + 15px);*/}
/*.award-inner-pad{padding: 15px 15px;}*/
.open #main-area {padding-left: 0;}
.award-cnt{padding: 15px 15px;}
.award-cnt ul{row-gap: 7px; justify-content: center; column-gap: 5px;}
.award-cnt ul li{flex: 0 1 auto; padding: 5px 15px; font-size: 13px; line-height: 18px; justify-content: left;}
.edit-sec{font-size: 13px;line-height: 20px; padding: 0 40px 0 15px; margin-bottom: 15px;}
.edit-sec span {width: 34px;height: 34px; right: 15px;}
.award-title h5{font-size: 14px; line-height: 20px;}
.main-title{font-size: 14px;line-height: 22px; margin-bottom: 15px; column-gap: 10px; flex-wrap: wrap;row-gap: 10px;}
/*.main-title span {width: 42px;height: 42px; font-size: 22px;line-height: 22px;}*/
.award-rgt {width: 100%;}
.btn.btn-primary.ideate-btn{height: 42px; font-size: 14px;line-height: 22px;}
.ideate-box{row-gap: 20px; width: 100%;}
.ideate-inner {width: 100%; padding: 15px;}
.ideate-inner textarea{font-size: 14px;line-height: 22px;}

.subject-sec{row-gap: 20px;}
.tone-lst{flex-wrap: wrap;column-gap: 0;row-gap: 10px;}
.tone-lst h3 {width: 100%;}
.tone-lst ul{width: 100%; row-gap: 10px; column-gap: 3%;}
.tone-lst ul li{flex: 0 1 48.5%; font-size: 14px; padding: 8px 10px;}
.subject-cnt{padding-right: 0;}
.subject-cnt h6{margin-bottom: 15px; font-size: 16px;}
.subject-cnt h5{font-size: 14px;line-height: 24px;}
.subject-cnt p{font-size: 14px;line-height: 24px;}
.show-btn.full-width{flex-wrap: wrap; row-gap: 15px;}
.btn.btn-primary.share-btn{min-width: 44px;height: 42px;}
.btn.btn-primary.copy{height: 42px;min-width: 100px; padding: 0px 20px; font-size: 14px;}
.btn.btn-primary.idea-btn, .btn.btn-primary.go-back{min-width: 100px;height: 42px; font-size: 14px; padding: 0px 15px;}
.edit-sec input {font-size: 14px; padding-right: 25px;}

.mythology-sec .myth-lft {float: left;width: 100%;height: inherit;padding-bottom: 30px;}
.mythology-sec .myth-rgt {float: left;width: 100%;padding-left: 0;}
.plot-cards ul li .plot-list {padding: 10px;}
.recognition-content {width: 100%;padding-right: 0;}
.characteristics {width: 100%;}
.myth-list{padding: 10px;}
.recognition-card{row-gap: 15px; padding: 10px;}
.plot-cards ul li {flex: 0 1 49%;}
.plot-cards ul li h3{font-size: 16px; padding: 0; margin-bottom: 15px;}

.myth-list ul li [type="radio"]:checked + .recognition-card label::before, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::before {left: -40px;}
.myth-list ul li [type="radio"]:checked + .recognition-card label::after, .myth-list ul li [type="radio"]:not(:checked) + .recognition-card label::after {left: -36px;}
.characteristics-tags{column-gap: 2%;row-gap: 10px;}
.characteristic-tag {flex: 0 1 49%; font-size: 13px;}
.myth-subtitle h2{font-size: 16px;}
.myth-submenu ul li a{font-size: 13px;}
.notification {width: 42px;height: 42px;}
.ideate-list ul{column-gap: 4%;}
.ideate-list ul li{flex: 0 1 48%;}

.generated-email h2{font-size: 24px; line-height: 32px; margin-bottom: 25px;}
.generated-btn{margin-top: 25px;}
.generated-email{padding: 25px 20px;}

/*popup form*/
.modal{--bs-modal-width: 400px !important;}
.login-form{padding: 40px 20px;}
.login-pad{padding: 0;}
.verification-box p{text-align: center;}
.login-form h2 {font-size: 20px;line-height: 28px; margin-bottom: 20px;}
.login-form .form-box input[type="email"]{height: 48px; padding: 10px 15px 10px;}
.verification-box ul li input[type="text"]{font-size: 20px;}
.btn-close {top: 15px;right: 15px;}
.popup-logo{margin-bottom: 20px;}
.verification-box p{line-height: 20px;}
.verification-box{margin-bottom: 20px;}
.btn.login-btn{padding: 10px;}
.or-txt {margin: 15px 0;}
.btn.gle-btn{padding: 10px 10px; font-size: 14px;line-height: 25px;margin-bottom: 20px;}

.thank-you{padding: 40px 20px;}
.circles {width: 110px;height: 110px; margin: 0 auto 20px;}
.circles::after {width: 70px;height: 50px;}
.thank-you h3{font-size: 22px;line-height: 28px;}

.check-inbox {padding: 40px 20px;}
.inbox-img{margin-bottom: 22px;}
.inbox-img img {width: 165px;}
.check-inbox h3{font-size: 22px;line-height: 28px;margin-bottom: 15px;}
.check-inbox p{font-size: 14px; line-height: 22px; margin-bottom: 15px;}

.unlock-email{padding: 40px 20px;}
.unlock-email img{width: 165px;}
.unlock-img{margin-bottom: 25px;}
.unlock-email h3{font-size: 22px;line-height: 28px; margin-bottom: 15px;}
.unlock-email p{font-size: 14px;line-height: 24px; margin-bottom: 15px;}
.unlock-btn{margin-top: 0;}
.unlock-btn{padding: 0 20px;}
.unlock-btn ul{row-gap: 15px;}
.unlock-btn ul li .btn.sign-unlock, .unlock-btn ul li .btn.login-unlock{font-size: 14px;}

.plot-card{padding: 20px 0;}
.plot-card h3{font-size: 22px; line-height: 28px; margin-bottom: 20px;}
.culture-list {margin-bottom: 30px; padding-right: 0;}
.culture-list ul li{font-size: 14px;}
.plot-list ul{row-gap: 20px; justify-content: center;}
.plot-list ul li {flex: 0 1 49%;}
.plot-list ul li .charact-lst ul{row-gap: 10px; justify-content: left;}
.stage{display: flex;flex-wrap: nowrap;white-space: nowrap;overflow-x: auto;scrollbar-width: none; justify-content: left;}

.btm-goback .btn.btn-primary {font-size: 14px; height: 42px; padding: 10px 20px;}
.btm-goback {padding: 10px 10px; width: 100%; margin-right: 0;}

.get-touch {width: 100%;margin: 10px auto 0;text-align: center;padding: 15px;}
.get-touch-inner {width: 100%;}
.get-touch h2{font-size: 24px; margin-bottom: 12px;}
.get-touch h2 span img {width: 32px;}
.get-touch p{font-size: 14px; line-height: 23px; margin-bottom: 20px;}
.get-touch p br{display: none;}
.get-touch-inner ul{row-gap: 15px;}
.get-touch-inner ul li .frm-input.checkbox label::after {width: 18px; height: 18px;}
.get-touch-inner ul li .frm-input.checkbox label{font-size: 13px; line-height: 22px; padding-left: 28px;}
.get-touch-inner ul li .form-control{font-size: 14px; height: 42px; border-radius: 5px;}
.get-touch-inner ul li .frm-input.checkbox input:checked + label::before {width: 10px;height: 7px;top: 10px;left: 4px;}
.get-touch-inner ul li .submit.btn.btn-primary{height: 42px; font-size: 14px;border-radius: 5px;}


.no-sidebar .header {width: 100%;display: flex;align-items: center;}
.award-list{width: 100%;}
.prompt-content-edit-sec{margin-bottom: 20px;}
.main-lft-pad {padding: 0; float: left;}
.main-title span {display: initial;}
.prompt-content-edit-sec .content {font-size: 14px;line-height: 20px; padding: 15px;}
.show-btn .skip-btn{font-size: 14px; justify-content: center; height: 42px;}
#head-rgt-lft-id{display: none !important;}
.custom-scrollbar-inner {padding: 8px 15px; font-size: 14px;}
.custom-scrollbar{column-gap: 10px !important;}
.plot-card-content-area .col-lg-12{padding: 0;}
.plot-list ul li h5{font-size: 18px; line-height: 25px;}
.loading-cnt p{font-size: 18px;line-height: 28px; text-align: center;}
.loading-cnt p br {display: none;}
.loading-cnt p span {display: inline;}
.myth-sidebar ul li.myth-sidebar-culture div {font-size: 14px;}
.olympian-title{column-gap: 0;row-gap: 10px;margin-bottom: 20px;}
.olympian-logo {width: 42px;height: 42px;}
.olympian-cnt {width: 100%;}
.olympian-cnt p {font-size: 14px;}
.loader-sec {padding: 30px 0;}
.loading img{width: 100px; margin: 0px auto 10px;}
.dot {width: 14px;height: 14px;}
.show-btn {padding: 10px 0;}
.rate-mail ul{row-gap: 5px; flex-wrap: wrap;}
.rate-mail ul li:first-child{flex: 0 1 100%; text-align: left;}
.show-rgt.full-width{justify-content: space-between; row-gap: 20px; padding: 0 10px;}
.show-rgt-btn .btn.btn-primary.share-btn {min-width: auto;height: auto;}


.myth-sidebar{padding: 15px 0 15px 8px;}
.myth-sidebar ul{row-gap: 7px; column-gap: 10px; display: flex;flex-wrap: nowrap;white-space: nowrap;overflow-x: auto;scrollbar-width: none;justify-content: left;}
.myth-sidebar ul li {flex: 0 1 auto; padding: 6px 10px;}
.mythology-sec .myth-rgt .myth-rgt-no-pad-inner {padding: 0 10px;}
.plot-list{margin-bottom: 30px;}
.btm-goback .btn.btn-primary.go-btn {order: 2;}
.myth-sidebar-title{padding-right: 10px;}
.btn.btn-primary.thankyou-btn{order: 1; font-size: 12px; line-height: 18px; padding: 10px; text-align: left; width: auto; height: 42px;}
.btn.btn-primary.go-back{order: 2;}
.show-lft.full-width{row-gap: 10px;}

.circle-bg{column-gap: 30px;}
.stage .text-center{flex-wrap: wrap; column-gap: 0; row-gap: 4px; justify-content: center; padding: 0 5px;}
.stage p{order: 2; width: 100%;}
.ideate-inner .btn.btn-primary.ideate-btn {right: 0;}
.show-rgt-btn{column-gap: 10px;}
.btn.btn-primary.share-btn span {font-size: 14px;}
.btn.btn-primary.share-btn span img {width: 15px;}
#formData {padding-left: 15px;}

.confirm-popup{padding: 20px;}
.confirm-popup .modal-title.h4{font-size: 24px; line-height: 28px;}
.confirm-popup .modal-footer{justify-content: center;}
.confirm-popup .modal-footer .btn.btn-secondary, .confirm-popup .modal-footer .btn.btn-primary{min-width: 100px; font-size: 14px;}
.confirm-popup .modal-body .alert.alert-info {padding: 10px; font-size: 14px;}

.main-menu ul li ul.dropdown {min-width: 242px; right: 0;}
.main-menu ul li ul.dropdown li {font-size: 13px;}
.main-menu ul li ul.dropdown li a.sign-out {width: 128px; font-size: 13px;}

.award-inner-pad.step4 {column-gap: 0; padding: 0 10px;}
.step4-lft {width: 100%;}
.step4-rgt {width: 100%;position: inherit;top: auto;right: auto;transform: none;}
.edit-sec.step4 {width: 100%;margin: 0 auto 20px 0;}
.show-btn.full-width{width: 100%;}
.show-rgt-btn .btn.btn-primary.share-btn{flex: 0 1 auto;}
.edit-inner{flex-basis: calc(100% - 15px);}

#layout-wrapper{margin-left: 70px;}
.open #layout-wrapper{margin-left: 70px;}

}  

/* Mobile Landscape Size (devices and browsers) */
@media screen and (max-width:639px) {

.tone-lst ul li{font-size: 13px; flex: 0 1 100%;}
.show-rgt{width: 100%;}
.show-lft{order: 2; width: 100%;}
.show-btn.full-width{justify-content: inherit;}
.plot-cards ul{column-gap: 0;}
.plot-cards ul li {flex: 0 1 100%;}
.btn.btn-primary.idea-btn, .btn.btn-primary.go-back{font-size: 13px; padding: 0px 10px;}

.plot-list ul{column-gap: 0; row-gap: 15px;}
.plot-list ul li{flex: 0 1 100%;}
.plot-list ul li p{min-height: inherit;}
.head-rgt ul{column-gap: 5px;}
.modal-content{width: 92% !important; margin: 0px auto;}

}



/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media screen and (max-width:479px) {

.show-rgt {display: flex;column-gap: 0;flex-wrap: wrap;row-gap: 10px;}
.btn.btn-primary.idea-btn, .btn.btn-primary.go-back {width: auto;}
.characteristic-tag{flex: 0 1 100%;}

.ideate-list ul li{flex: 0 1 100%;}
.generated-email h2{font-size: 20px; line-height: 28px;}
.generated-btn ul{column-gap: 0; row-gap: 10px;}
.generated-btn ul li{flex: 0 1 100%;}
.generated-email ul{row-gap: 20px; justify-content: center;}
.generated-email ul li{flex: 0 1 32%;}
.show-btn{row-gap: 10px; column-gap: 0;}
.show-btn .skip-btn{width: 100%; order: 2;}
.btn.btn-primary.ideate-btn{width: 100%;}
.btm-goback{column-gap: 0; row-gap: 10px;}
.btm-goback .btn.btn-primary{width: 100%;}
.show-rgt.full-width{justify-content: left;}
.btn.btn-primary.thankyou-btn{width: 100%;}


}

@media screen and (max-width:359px) {



}
